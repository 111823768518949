import { Col, DatePicker, Form, Input, InputNumber, Row, Select, Space } from "antd";
import { DailyLinePlanContext } from "context/DailyLinePlanContext";
import Section from "./Section";
import { useContext } from "react";

export default function DailyLinePlanForm() {
  const {
    form,
    onFinish,
    options,
    isLineSelected,
    isStyleSelected,
    onChangeId,
    onChangeLine,
    onChangeStyle,
    onChangeSubStyle,
    onChangeLineChief,
    dailyLinePlanData,
    onChangeDailyLinePlanData,
    onChangeOperation,
    onChangeSupervisorId,
    onChangeOperationId,
    onAdd,
    onCopy,
    onDelete,
    debouncedFetchStylesOption,
    debouncedFetchSubStylesOption,
  } = useContext(DailyLinePlanContext);


  return (
    <Form form={form} onFinish={onFinish}>
      <br />
      <Row gutter={24} align={"middle"} justify={"space-between"}>
        <Col span={6}>
          <Form.Item
            label="Date"
            name="date"
            rules={[{ required: true, message: "Please select Date!" }]}
          >
            <DatePicker
              showTime
              placeholder="Select Date"
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item
            label="Line"
            name="line"
            rules={[{ required: true, message: "Please select Line!" }]}
          >
            <Select placeholder="Select Line" onChange={onChangeLine}>
              {options.lines.map((line) => (
                <Select.Option key={line.value} value={line.value}  >
                  {line.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item
            label="Style"
            name="style"
            rules={[{ required: isLineSelected, message: "Please select Style!" }]}
          >
            <Select
              disabled={!isLineSelected}
              placeholder="Select Style"
              onChange={onChangeStyle}
              showSearch
              optionFilterProp="children"
              onSearch={(value) =>
                debouncedFetchStylesOption({ name: value })
              }
            >
              {options?.styles?.map((style) => (
                <Select.Option key={style.value} value={style.value}>
                  {style.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label="Sub-Style"
            name="sub_style_id"
          >
            <Select
              disabled={!isLineSelected}
              placeholder="Select Sub-Style"
              showSearch
              optionFilterProp="children"
              onSearch={(value) =>
                debouncedFetchSubStylesOption({ name: value })
              }
              onChange={(value) => {
                onChangeSubStyle(value);
              }}
            >
              {options?.subStyles?.map((style) => (
                <Select.Option key={style.value} value={style.value}>
                  {style.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        {
          form.getFieldValue('buyer') &&
          <Col span={6}>
            <Form.Item
              label="Buyer"
              name="buyer"
            >
              <Input placeholder="Select a Style" disabled />
            </Form.Item>
          </Col>}
        {
          form.getFieldValue('factory') &&
          <Col span={6}>
            <Form.Item
              label="Factory"
              name="factory"
            >
              <Input placeholder="Select a Style" disabled />
            </Form.Item>
          </Col>
        }
        <Col span={12}>
          <Form.Item
            label="Sales Contract"
            name="sales_contract"
            // rules={[{ required: isStyleSelected, message: "Please select Style or Sub-Style" }]}
          >
            <InputNumber disabled placeholder="Enter Style or Sub-Style" style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label="Hourly Target"
            name="hourly_target_qty"
          >
            <InputNumber placeholder="Enter hourly target qty ..." style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label="PO"
            name="po_map_id"
            rules={[{ required: false, message: "Please select PO!" }]}
          >
            <Select disabled={!isStyleSelected} placeholder="Select PO">
              {options?.po?.map((po) => (
                <Select.Option key={po.value} value={po.value}>
                  {po.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={18}>
          <Form.Item label="Line Chief">
            <Space.Compact style={{ width: "100%" }}>
              <Form.Item name={["line_chief", "line_chief_id"]} noStyle hidden>
                <Input
                  style={{ width: "30%" }}
                  placeholder="ID Card No."
                  hidden
                />
              </Form.Item>
              <Form.Item name={["line_chief", "id"]} noStyle>
                <Input
                  style={{ width: "30%" }}
                  placeholder="ID Card No."
                  onChange={(e) => onChangeLineChief(e?.target?.value)}
                />
              </Form.Item>
              <Form.Item name={["line_chief", "name"]} noStyle>
                <Input
                  style={{ width: "33.33%" }}
                  placeholder="Employee Name"
                  disabled
                />
              </Form.Item>
            </Space.Compact>
          </Form.Item>
        </Col>
        {/* <Col span={6}></Col> */}
        {dailyLinePlanData?.operation_bulletine_details?.map(
          (operationBulletineDetail, index) => (
            <Section
              key={index}
              onAdd={onAdd}
              section_index={index}
              operationBulletineDetail={operationBulletineDetail}
              onChangeDailyLinePlanData={onChangeDailyLinePlanData}
              options={options}
              onChangeId={onChangeId}
              onChangeSupervisorId={onChangeSupervisorId}
              onChangeOperation={onChangeOperation}
              onChangeOperationId={onChangeOperationId}
              onCopy={onCopy}
              onDelete={onDelete}
            />
          ),
        )}
      </Row>
    </Form>
  );
}
