import { Card, Table, Typography, message } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router";
import {
  DELETE_PRODUCTION_DETAILS,
  EXPORT_PRODUCTION_DETAILS,
  GET_PRODUCTION_DETAILS,
  VIEW_PRODUCTION_DETAILS,
} from "../../../apiServices/API_ENDPOINTS";
import { getData, postData } from "../../../apiServices/common";
import AppContent from "../../../common/AppContent";
import AppPageHeader from "../../../common/PageHeader";
import CustomFilter from "../../Common/CustomFilter";
import ExtraContent from "../../Configuration/CommonComponents/ExtraContent";
import FilterComponent from "../../Configuration/CommonComponents/FilterComponent";

const ProductionDetails = () => {
  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;

  // States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [refresh, setRefresh] = useState(0);
  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(false);

  // Antd
  const { Title } = Typography;

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};

    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  const cutPath = (path) => {
    const updatedPath = path ? path.split("public/production_details/")[1] : "";
    return updatedPath;
  };

  const getAllDetails = useCallback(async (filterValues) => {
    setLoading(true);
    const query = `${GET_PRODUCTION_DETAILS}`;
    const bodyData = {
      per_page: filterValues?.per_page || 10,
      ...filterValues,
    };

    const response = await getData(query, false, bodyData);

    if (response?.status === 200) {
      setLoading(false);
      setTableData(response?.data?.data);
    } else {
      setLoading(false);
      message.error("Error getting details data");
    }
  }, []);

  const exportDetails = async (filterValues) => {
    const { sales_contract_id, style_id, buying_office_id, buyer_id } = filterValues;
    const bodyData = {
      ...(sales_contract_id && { sales_contract_id }),
      ...(buying_office_id && { buying_office_id }),
      ...(buyer_id && { buyer_id }),
      ...(style_id && { style_id }),
    };
    if (
      Object.values(bodyData).length &&
      !Object.values(bodyData).some((val) => val === undefined)
    ) {
      setLoading(true);

      const query = `${EXPORT_PRODUCTION_DETAILS}`;

      const response = await getData(
        query,
        false,
        bodyData,
        { "Content-Type": "blob" },
        "arraybuffer",
      );

      if (response && response?.data) {
        // Download the file
        const outputFilename = `production_details_report.xlsx`;

        // If you want to download file automatically using link attribute.
        const url = URL.createObjectURL(
          new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          }),
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", outputFilename);
        document.body.appendChild(link);
        link.click();
        link.remove();

        setLoading(false);
        message.success("Fabric Exported Successfully");
        refetch();
      } else {
        setLoading(false);
        message.error("Something went wrong");
      }
    }
  };

  const previewFunction = async (filterValues) => {
    setLoading(true);

    const bodyData = {
      ...filterValues,
    };

    const query = `${VIEW_PRODUCTION_DETAILS}`;
    const response = await postData(query, bodyData);

    if (response?.code === 200) {
      setLoading(false);
      setTableData(response?.data);
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  // Effects
  // useEffect(() => {
  //   getAllDetails();
  // }, [refresh, getAllDetails]);

  const refetch = () => {
    setRefresh(refresh + 1);
  };

  const deleteSelectedTracking = async (name) => {
    setLoading(true);
    const updatedName = cutPath(name);
    const query = `${DELETE_PRODUCTION_DETAILS}`;
    const bodyData = {
      name: updatedName,
    };
    const response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      setLoading(false);
      message.success("Production Details Deleted Successfully");
      refetch();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const openFile = (name) => {
    const URL = `${process.env.REACT_APP_BASE_URL}/storage/production_details/${name}`;
    window.open(URL, "_blank", "noreferrer");
  };

  const tableColumns = [
    {
      title: "Cutting to Shipping Production Details",
      children: [
        {
          title: "Buying Office",
          dataIndex: "buying_office",
          key: "buying_office",
          width: 150,
        },
        {
          title: "Buyer",
          dataIndex: "buyer",
          key: "buyer",
          width: 150,
        },
      ],
    },
    {
      title: "Sales Contract",
      dataIndex: "sales_contract",
      key: "sales_contract",
      width: 150,
    },
    {
      title: "Style",
      dataIndex: "style",
      key: "style",
      width: 150,
    },
    {
      title: "PO",
      dataIndex: "po",
      key: "po",
      width: 150,
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
      width: 150,
    },
    {
      title: "Color",
      dataIndex: "color",
      key: "color",
      width: 150,
    },
    {
      title: "Substyle",
      dataIndex: "sub_style",
      key: "sub_style",
      width: 150,
    },
    {
      title: "Size",
      dataIndex: "size",
      key: "size",
      width: 150,
    },
    {
      title: "O.Qty",
      dataIndex: "order_quantity",
      key: "order_quantity",
      width: 150,
    },
    {
      title: "Start",
      dataIndex: "cutting_start",
      key: "cutting_start",
      width: 150,
    },
    {
      title: "End",
      dataIndex: "cutting_end",
      key: "cutting_end",
      width: 150,
    },
    {
      title: "Cutting Qty",
      dataIndex: "cutting_quantity",
      key: "cutting_quantity",
      width: 150,
    },
    {
      title: "Start",
      dataIndex: "assign_start",
      key: "assign_start",
      width: 150,
    },
    {
      title: "End",
      dataIndex: "assign_end",
      key: "assign_end",
      width: 150,
    },
    {
      title: "Assigned Qty",
      dataIndex: "assigned_quantity",
      key: "assigned_quantity",
      width: 150,
    },
    {
      title: "Start",
      dataIndex: "sewing_start",
      key: "sewing_start",
      width: 150,
    },
    {
      title: "End",
      dataIndex: "sewing_end",
      key: "sewing_end",
      width: 150,
    },
    {
      title: "Sewing Qty",
      dataIndex: "sewing_quantity",
      key: "serial",
      width: 150,
    },
    {
      title: "Start",
      dataIndex: "folding_start",
      key: "folding_start",
      width: 150,
    },
    {
      title: "End",
      dataIndex: "folding_end",
      key: "folding_end",
      width: 150,
    },

    {
      title: "Folding Qty",
      dataIndex: "folding_quantity",
      key: "folding_quantity",
      width: 150,
    },
    {
      title: "Start",
      dataIndex: "packing_start",
      key: "packing_start",
      width: 150,
    },
    {
      title: "End",
      dataIndex: "packing_end",
      key: "packing_end",
      width: 150,
    },
    {
      title: "Packing Qty",
      dataIndex: "packing_quantity",
      key: "packing_quantity",
      width: 150,
    },
    {
      title: "Ex-Factory Date",
      dataIndex: "ex_factory_start",
      key: "ex_factory_start",
      width: 150,
    },
    {
      title: "Ex-Factory Qty",
      dataIndex: "ex_factory_quantity",
      key: "ex_factory_quantity",
      width: 150,
    },
  ];

  return (
    <>
      <AppContent
        header={
          <AppPageHeader
            extra={
              <ExtraContent
                refresh={refresh}
                setRefresh={setRefresh}
                handleAddItemOpen={exportDetails}
                hideAddButton={true}
                showFilter={showFilter}
                setShowFilter={setShowFilter}
              />
            }
            title={<Title level={5}>Production Details</Title>}
          />
        }
        content={
          <>
            <div className="containt-body">
              <Card>
                <FilterComponent
                  tableData={tableData}
                  filterAPI={exportDetails}
                  filterName={"production_details"}
                  searchButtonText={"Export"}
                  defaultOpen
                  setShowFilter={setShowFilter}
                  showFilter={showFilter}
                  isPreview={true}
                  previewFunction={previewFunction}
                />
              </Card>
              <Table
                loading={loading}
                dataSource={tableData}
                columns={tableColumns}
                pagination
                scroll={{ y: "max-content" }}
                bordered
              />
            </div>
          </>
        }
      />
    </>
  );
};

export default ProductionDetails;
