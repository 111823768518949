import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Space,
  Switch,
} from "antd";
import moment from "moment";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ADD_COLORS,
  BUYER_INQUIRY_LIST_ENDPOINT,
  GARMENT_CATEGORY_LIST_ENDPOINT,
  GARMENT_FOR_LIST_ENDPOINT,
  GARMENT_TYPE_LIST_ENDPOINT,
  GET_BUYING_OFFICE_ENDPOINT,
  GET_COLORS,
  STYLE_ADD_DOC,
  STYLE_MERCHANDISING_ADD,
  STYLE_MERCHANDISING_UPDATE,
} from "../../../apiServices/API_ENDPOINTS";
import { getData, postData } from "../../../apiServices/common";
import { alertPop } from "../../../apiServices/common/helper";
import HandleNotification from "../../../common/Notification";
import { isArrayAndHasValue } from "../../../utils/functions";
import DocumentUpload from "../../Common/DocumentUpload";
import SubStyle from "./SubStyle";

const AddStyleForm = forwardRef((props, ref) => {
  // Props
  const { permitInfo, view, isCopy, inquiryInfo, fileData } = props;

  // States
  const [buyerInquiryList, setBuyerInquiryList] = useState([]);
  const [buyingOffice, setBuyingOffice] = useState([]);
  const [garmentCategoryList, setGarmentCategoryList] = useState([]);
  const [buyer, setBuyer] = useState([]);
  const [brand, setBrand] = useState([]);
  const [garmentTypeList, setGarmentTypeList] = useState([]);
  const [garmentForList, setGarmentForList] = useState([]);

  const [colorsList, setColorsList] = useState([]);
  const [tableState, setTableState] = useState([]);
  const [colorName, setColorName] = useState();
  const [filesList, setFileList] = useState([]);
  const [selectedBuyingOffice, setSelectedBuyingOffice] = useState(null);
  const [selectedBuyer, setSelectedBuyer] = useState(null);
  const [subcontractStatusOption, setSubcontractStatusOption] = useState([]);
  const [documents, setDocuments] = useState([]);

  // Antd Constants
  const [form] = Form.useForm();
  const { Option } = Select;
  const { TextArea } = Input;
  const subcontract_status = Form.useWatch("subcontract_status", form);

  // Router
  const navigate = useNavigate();

  useEffect(() => {
    // CAll API and get data on first load
    getBuyingOfficeList();
    getColorList();
    getAllGarmentCategory();
    getAllGarmentType();
    getAllGarmentFor();
    getAllBuyerInquiry();
    getSubcontractStatusOption();
  }, []);

  useEffect(() => {
    if (inquiryInfo) {
      form.setFieldsValue({
        inquiry_id: inquiryInfo?.id,
        received_date: inquiryInfo?.received_date
          ? moment(inquiryInfo?.received_date, "YYYY-MM-DD")
          : null,
        buying_office_id: inquiryInfo?.buying_office_info?.id,
        buyer_id: inquiryInfo?.buyer_info?.id,
        brand_id: inquiryInfo?.brand_info?.id,
        garment_category_id: inquiryInfo?.garment_category_info?.id,
        garment_type_id: inquiryInfo?.garment_type_info?.id,
        garment_for_id: inquiryInfo?.garment_for_info?.id,
        description: inquiryInfo?.description,
      });

      setSelectedBuyingOffice(inquiryInfo?.buying_office_info?.id);
      setSelectedBuyer(inquiryInfo?.buyer_info?.id);
    }

    if (isArrayAndHasValue(fileData)) {
      const customFileData = fileData.map((item) => {
        return {
          id: item?.id,
          name: item?.document_name,
          document: item?.document,
          path: item?.document,
          attachment_type: item?.attachment_type,
          reference_no: item?.reference_no,
        };
      });

      setFileList(customFileData);
    }
  }, [inquiryInfo, fileData, form]);

  useEffect(() => {
    if (selectedBuyingOffice) {
      getBuyersList(selectedBuyingOffice, { from: "inquiry" });
    }

    if (selectedBuyer) {
      getBrand(selectedBuyer, { from: "inquiry" });
    }
  }, [selectedBuyingOffice, selectedBuyer]);

  useImperativeHandle(ref, () => ({
    submit() {
      form
        .validateFields()
        .then(async (values) => {
          if (!tableState?.length) {
            HandleNotification(
              "error",
              "bottomRight",
              "Please add sub style",
              null,
            );
            return null;
          }
          const updateQuery = `${STYLE_MERCHANDISING_UPDATE}${permitInfo?.style?.id}`;
          const addQuery = `${STYLE_MERCHANDISING_ADD}`;
          const query =
            permitInfo?.style?.id && !isCopy ? updateQuery : addQuery;
          const payload = {
            buying_office_id: values?.buying_office_id,
            buyer_id: values?.buyer_id,
            brand_id: values?.brand_id,
            style_no: values?.style_no || "",
            description: values?.description || "",
            received_date: values?.received_date
              ? moment(values?.received_date).format("YYYY-MM-DD")
              : null,
            sales_contract_id: values?.sales_contract_id || "",
            documents: isArrayAndHasValue(filesList)
              ? filesList?.map((item) => {
                  return {
                    id: item?.id,
                    document_name: item?.name,
                    attachment_type: item?.attachment_type,
                    reference_no: item?.reference_no,
                    is_delete: item?.is_delete || 0,
                  };
                })
              : [],
            sub_styles:
              isArrayAndHasValue(tableState) &&
              tableState?.map((item) => {
                return {
                  id: item?.index || 0,
                  color_id: item?.color_id || null,
                  sub_style_no: item?.sub_style_no || "",
                  is_delete: item?.is_delete || 0,
                };
              }),
            garment_category_id: values?.garment_category_id || null,
            garment_type_id: values?.garment_type_id || null,
            garment_for_id: values?.garment_for_id || null,
            status: values?.status ? 1 : 0,
            inquiry_id: values?.inquiry_id || null,
            subcontract_status: values?.subcontract_status ? 1 : 0,
            sub_contract_factory_id: values?.sub_contract_factory_id || null,
            knit_woven: values?.knit_woven || null,
            embroidery: values?.embroidery ? 'Yes' : 'No',
            printing: values?.printing ? 'Yes' : 'No',
            washing: values?.washing ? 'Yes' : 'No',
            smoking: values?.smoking ? 'Yes' : 'No',
            pintuck: values?.pintuck ? 'Yes' : 'No',
            snap_button: values?.snap_button ? 'Yes' : 'No',
          };

          if (!isArrayAndHasValue(payload["sub_styles"])) {
            HandleNotification(
              "error",
              "bottomRight",
              "Please add sub style",
              null,
            );
            return null;
          }

          const response = await postData(query, payload);

          if (response) {
            alertPop(
              "success",
              `Style ${
                permitInfo?.style?.id && !isCopy ? "Updated" : "Added"
              } Successfully`,
            );
            // navigate("/merchandising/style");
          } else {
            HandleNotification(
              "error",
              "bottomRight",
              "Something went wrong",
              null,
            );
          }
        })
        .catch((errorInfo) => {
          HandleNotification(
            "error",
            "bottomRight",
            "Please enter all fields",
            null,
          );
        });
    },
    discart() {
      form.resetFields();
    },
  }));

  const getBuyingOfficeList = async (filterValues) => {
    const query = `${GET_BUYING_OFFICE_ENDPOINT}`;
    const bodyData = {
      per_page: 10,
      ...filterValues,
    };
    const response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      setBuyingOffice(response?.data?.data?.data || []);
    }
  };

  const getBuyersList = async (value, filterValues) => {
    if (!filterValues) {
      form.resetFields(["buyer_id", "brand_id"]);
    }

    const query = `/api/buying_office/${value}/edit`;
    const bodyData = {
      ...(filterValues?.name && { name: filterValues?.name }),
      per_page: 10,
    };
    const response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      setBuyer(response?.data?.data?.buyer || []);
    }
  };

  const getBrand = async (value, filterValues) => {
    if (!filterValues) {
      form.resetFields(["brand_id"]);
    }

    const bodyData = {
      ...(filterValues?.name && { name: filterValues?.name }),
      per_page: 10,
    };
    const response = await getData(
      `/api/buyers/${value}/edit`,
      false,
      bodyData,
    );

    if (response && response?.data?.code === 200) {
      setBrand(response?.data?.data?.brands);
    }
  };

  const tableHandeler = () => {
    var data = {};
    data["index"] = 0;
    data["color_id"] = form.getFieldValue("color_id");
    data["sub_style_no"] = form.getFieldValue("sub_style_no");

    if (colorsList?.length) {
      let color = colorsList.find(
        (item) => item?.id === form.getFieldValue("color_id"),
      );
      data["color"] = color.name;
    }
    if (form.getFieldValue("style_no")) {
      setTableState((oldArray) => [...oldArray, data]);
    }
  };

  const getColorList = async () => {
    const bodyData = {
      per_page: 10000,
    };
    const res = await getData(GET_COLORS, false, bodyData);

    if (res) {
      setColorsList(res?.data?.data?.data);
    }
  };

  const addColorItem = async () => {
    let res = await postData(ADD_COLORS, { name: colorName, status: 1 });

    if (res) {
      let masterData = res?.data;
      setColorsList((oldArray) => [...oldArray, masterData]);
    }
  };

  const getAllGarmentCategory = async (filterValues) => {
    const bodyData = {
      per_page: 20,
      ...filterValues,
    };
    const query = `${GARMENT_CATEGORY_LIST_ENDPOINT}`;

    const response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      setGarmentCategoryList(response?.data?.data?.data);
    }
  };

  const getAllGarmentType = async (filterValues) => {
    const bodyData = {
      per_page: 20,
      ...filterValues,
    };
    const query = `${GARMENT_TYPE_LIST_ENDPOINT}`;

    const response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      setGarmentTypeList(response?.data?.data?.data);
    }
  };

  const getAllGarmentFor = async (filterValues) => {
    const bodyData = {
      per_page: 20,
      ...filterValues,
    };
    const query = `${GARMENT_FOR_LIST_ENDPOINT}`;

    const response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      setGarmentForList(response?.data?.data?.data);
    }
  };

  const getAllBuyerInquiry = async (filterValues) => {
    const bodyData = {
      per_page: 20,
      ...filterValues,
    };
    const query = `${BUYER_INQUIRY_LIST_ENDPOINT}`;

    const response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      setBuyerInquiryList(response?.data?.data?.data);
    }
  };

  const getSubcontractStatusOption = async (filterValues) => {
    const bodyData = {
      ...(filterValues?.name && { name: filterValues?.name }),
      per_page: 10,
    };
    const response = await getData(
      `/api/sub-contract-factory`,
      false,
      bodyData,
    );

    if (response && response?.data?.code === 200) {
      setSubcontractStatusOption(response?.data?.data?.data);
    }
  };

  const onHandleChangeBuyerInquiryList = (value) => {
    const selectedInquiry = buyerInquiryList.find((item) => item.id === value);
    if (selectedInquiry) {
      const style_document = Array.isArray(
        selectedInquiry?.style_inquiry_map?.style_info?.style_document,
      )
        ? selectedInquiry?.style_inquiry_map?.style_info?.style_document
        : [];
      setDocuments(style_document);
      // setSubcontractStatusOption
      if (
        !subcontractStatusOption?.some(
          (item) => item.id === selectedInquiry?.sub_contract_factory_id,
        )
      ) {
        setSubcontractStatusOption((oldArray) => [
          {
            id: selectedInquiry?.sub_contract_factory_id,
            name: selectedInquiry?.factory_info?.name,
            address: selectedInquiry?.factory_info?.address,
          },
          ...oldArray,
        ]);
      }
      // buyingOffice
      if (
        !buyingOffice.some(
          (item) => item.id === selectedInquiry?.buying_office_id,
        )
      ) {
        setBuyingOffice((oldArray) => [
          {
            id: selectedInquiry?.buying_office_id,
            name: selectedInquiry?.buying_office_info?.name,
          },
          ...oldArray,
        ]);
      }

      // buyer

      if (
        !buyer.some((item) => item.id === selectedInquiry?.buyer_id) &&
        selectedInquiry?.buyer_id
      ) {
        setBuyer((oldArray) => [
          {
            id: selectedInquiry?.buyer_id,
            name: selectedInquiry?.buyer_info?.name,
          },
          ...oldArray,
        ]);
      }

      // brand
      if (
        !brand.some((item) => item.id === selectedInquiry?.brand_id) &&
        selectedInquiry?.brand_id
      ) {
        setBrand((oldArray) => [
          {
            id: selectedInquiry?.brand_id,
            name: selectedInquiry?.brand_info?.name,
          },
          ...oldArray,
        ]);
      }
      form.setFieldsValue({
        buying_office_id: selectedInquiry?.buying_office_id,
        buyer_id: selectedInquiry?.buyer_id,
        brand_id: selectedInquiry?.brand_id,
        garment_category_id: selectedInquiry?.garment_category_id,
        garment_type_id: selectedInquiry?.garment_type_id,
        garment_for_id: selectedInquiry?.garment_for_id,
        description: selectedInquiry?.description,
        sub_contract_factory_id: selectedInquiry?.sub_contract_factory_id,
        address: selectedInquiry?.factory_info?.address,
        // subcontract_status: selectedInquiry?.subcontract_status ? true : false,
      });
    }
  };

  useEffect(() => {
    if (permitInfo) {
      if (
        !subcontractStatusOption?.some(
          (item) => item.id === permitInfo?.style?.sub_contract_factory_id,
        )
      ) {
        if (permitInfo?.style?.sub_contract_factory_id) {
          setSubcontractStatusOption((oldArray) => [
            {
              id: permitInfo?.style?.sub_contract_factory_id,
              name: permitInfo?.style?.factory_info?.name,
              address: permitInfo?.style?.factory_info?.address,
            },
            ...oldArray,
          ]);
        }
      }
      if (
        !buyerInquiryList.some(
          (item) => item.id === permitInfo?.style?.inquiry_id,
        )
      ) {
        setBuyerInquiryList((oldArray) => [
          {
            id: permitInfo?.style_inquiry_map?.inquiry_id,
            inquiry_no:
              permitInfo?.style?.style_inquiry_map?.inquiry_info?.inquiry_no,
          },
          ...oldArray,
        ]);
      }
      if (
        !buyingOffice.some(
          (item) => item.id === permitInfo?.style?.buying_office_id,
        )
      ) {
        const clone = [...buyingOffice];
        clone.push({
          id: permitInfo?.style?.buying_office_id,
          name: permitInfo?.style?.buying_office_info?.name,
        });
        setBuyingOffice(clone);
      }

      if (
        !buyer.some((item) => item.id === permitInfo?.style?.buyer_id) &&
        permitInfo?.style?.buyer_id
      ) {
        setBuyer((oldArray) => [
          {
            id: permitInfo?.style?.buyer_id,
            name: permitInfo?.style?.buyer_info?.name,
          },
          ...oldArray,
        ]);
      }

      if (
        !brand.some((item) => item.id === permitInfo?.style?.brand_id) &&
        permitInfo?.style?.brand_id
      ) {
        setBrand((oldArray) => [
          {
            id: permitInfo?.style?.brand_id,
            name: permitInfo?.style?.brand_info?.name,
          },
          ...oldArray,
        ]);
      }

      if (
        !garmentCategoryList.some(
          (item) => item.id === permitInfo?.style?.garment_category_id,
        )
      ) {
        setGarmentCategoryList((oldArray) => [
          {
            id: permitInfo?.style?.garment_category_id,
            name: permitInfo?.style?.garment_category_info?.name,
          },
          ...oldArray,
        ]);
      }

      if (
        !garmentTypeList.some(
          (item) => item.id === permitInfo?.style?.garment_type_id,
        )
      ) {
        setGarmentTypeList((oldArray) => [
          {
            id: permitInfo?.style?.garment_type_id,
            name: permitInfo?.style?.garment_type_info?.name,
          },
          ...oldArray,
        ]);
      }

      if (
        !garmentForList.some(
          (item) => item.id === permitInfo?.style?.garment_for_id,
        )
      ) {
        setGarmentForList((oldArray) => [
          {
            id: permitInfo?.style?.garment_for_id,
            name: permitInfo?.style?.garment_for_info?.name,
          },
          ...oldArray,
        ]);
      }

      if (permitInfo?.style?.buying_office_id) {
        getBuyersList(permitInfo?.style?.buying_office_id);
      }

      if (permitInfo?.style?.buyer_id) {
        getBrand(permitInfo?.style?.buyer_id);
      }
      const style_document = Array.isArray(
        permitInfo?.style?.style_inquiry_map?.inquiry_info?.documents,
      )
        ? permitInfo?.style?.style_inquiry_map?.inquiry_info?.documents
        : [];

      setDocuments(style_document);

      form.setFieldsValue({
        brand_id: permitInfo?.style?.brand_id,
        buyer_id: permitInfo?.style?.buyer_id,
        buying_office_id: permitInfo?.style?.buying_office_id,
        description: permitInfo?.style?.description,
        style_no: permitInfo?.style?.style_no,
        received_date: permitInfo?.style?.received_date
          ? moment(permitInfo?.style?.received_date, "YYYY-MM-DD")
          : undefined,
        inquiry_id: permitInfo?.style?.style_inquiry_map?.inquiry_id,
        garment_category_id: permitInfo?.style?.garment_category_info?.id,
        garment_type_id: permitInfo?.style?.garment_type_info?.id,
        garment_for_id: permitInfo?.style?.garment_for_info?.id,
        subcontract_status: permitInfo?.style?.subcontract_status,
        sub_contract_factory_id: permitInfo?.style?.sub_contract_factory_id,
        address: permitInfo?.style?.factory_info?.address,
        knit_woven: permitInfo?.style?.knit_woven || null,
        embroidery: permitInfo?.style?.embroidery === 'Yes' ? true : false,
        printing: permitInfo?.style?.printing === 'Yes' ? true : false,
        washing: permitInfo?.style?.washing === 'Yes' ? true : false,
        smoking: permitInfo?.style?.smoking === 'Yes' ? true : false,
        pintuck: permitInfo?.style?.pintuck === 'Yes' ? true : false,
        snap_button: permitInfo?.style?.snap_button === 'Yes' ? true : false,
      });

      setTableState(
        permitInfo?.style?.sub_style.map((item) => {
          return {
            index: item?.id,
            color_id: item?.color_info?.id,
            sub_style_no: item?.sub_style_no,
            color: item?.color_info?.name,
          };
        }),
      );

      setFileList(
        permitInfo.documents.map((item) => {
          return {
            id: item?.id,
            name: item?.document_name,
            document: item?.document,
            path: item?.document,
            attachment_type: item?.attachment_type,
            reference_no: item?.reference_no,
          };
        }),
      );
    }
  }, [permitInfo]);

  return (
    <div className="containt-body pt-3">
      <Form layout="vertical" form={form} name="control-hooks">
        <Card>
          <Row gutter={6}>
            <Col span={4}>
              <Form.Item
                name="subcontract_status"
                valuePropName="checked"
                label=" "
              >
                <Checkbox>Sub-Contract</Checkbox>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                label="Style No."
                name="style_no"
                rules={[
                  {
                    required: true,
                    message: "Please input Style No!",
                  },
                ]}
                disabled={view}
              >
                <Input disabled={view} placeholder="Style No." size="small" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Buyer Inquiry" name="inquiry_id">
                <Select
                  showSearch
                  placeholder="Select buyer inquiry"
                  optionFilterProp="children"
                  disabled={view}
                  size="small"
                  onSearch={(value) => getAllBuyerInquiry({ name: value })}
                  allowClear
                  onChange={(value) => onHandleChangeBuyerInquiryList(value)}
                  onClear={() => {
                    form.setFieldsValue({
                      buying_office_id: null,
                      buyer_id: null,
                      brand_id: null,
                      garment_category_id: null,
                      garment_type_id: null,
                      garment_for_id: null,
                      description: null,
                      sub_contract_factory_id: null,
                      address: null,
                      // subcontract_status: null act_status ? true : false,
                    });
                  }}
                >
                  {isArrayAndHasValue(buyerInquiryList) &&
                    buyerInquiryList?.map((inquiryItem, index) => (
                      <Option
                        value={inquiryItem?.id}
                        key={inquiryItem?.id || index}
                      >
                        {inquiryItem?.inquiry_no || ""}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                label="Receive Date by"
                name="received_date"
                rules={[
                  {
                    required: true,
                    message: "Please input Receive Date!",
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  disabled={view}
                  size="small"
                />
              </Form.Item>
            </Col>
            {subcontract_status ? (
              <>
                <Col span={4}>
                  <Form.Item
                    label="Factory Name"
                    name="sub_contract_factory_id"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Factory Name!",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Select a Factory Name"
                      allowClear
                      size="small"
                      onChange={(value) => {
                        const selectedFactory = subcontractStatusOption.find(
                          (item) => item.id === value,
                        );
                        form.setFieldsValue({
                          address: selectedFactory?.address,
                        });
                      }}
                    >
                      {isArrayAndHasValue(subcontractStatusOption) &&
                        subcontractStatusOption.map((sup) => (
                          <Option value={sup?.id} key={sup?.id}>
                            {sup.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item label="Address" name="address">
                    <Input placeholder="Address" size="small" disabled />
                  </Form.Item>
                </Col>
              </>
            ) : (
              <>
                <Col span={4}>
                  <Form.Item
                    label="Buying office"
                    name="buying_office_id"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Buying office!",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Select a Buying office"
                      onChange={(value) => {
                        if (value) {
                          getBuyersList(value);
                          setSelectedBuyingOffice(value);
                        } else {
                          form.resetFields(["buyer_id", "brand_id"]);
                          setBuyer([]);
                          setBrand([]);
                          setSelectedBuyingOffice(null);
                        }
                      }}
                      optionFilterProp="children"
                      disabled={view}
                      size="small"
                      onSearch={(value) => {
                        getBuyingOfficeList({ name: value });
                      }}
                      allowClear
                      onClear={() => getBuyingOfficeList()}
                    >
                      {isArrayAndHasValue(buyingOffice) &&
                        buyingOffice.map((sup) => (
                          <Option value={sup?.id} key={sup?.id}>
                            {sup.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    label="Buyer"
                    name="buyer_id"
                    rules={[
                      { required: true, message: "Please Select Buyer!" },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Select a Buyer"
                      onChange={(value) => {
                        if (value) {
                          getBrand(value);
                          setSelectedBuyer(value);
                        } else {
                          form.resetFields(["brand_id"]);
                          setBrand([]);
                          setSelectedBuyer(null);
                        }
                      }}
                      optionFilterProp="children"
                      disabled={view}
                      size="small"
                      onSearch={(value) =>
                        getBuyersList(selectedBuyingOffice, { name: value })
                      }
                      allowClear
                      onClear={() => getBuyersList(selectedBuyingOffice)}
                    >
                      {isArrayAndHasValue(buyer) &&
                        buyer.map((buy) => (
                          <Option value={buy?.id} key={buy?.id}>
                            {buy.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    label="Brand"
                    name="brand_id"
                    rules={[{ required: true, message: "Please input brand" }]}
                  >
                    <Select
                      showSearch
                      placeholder="Select a Brand"
                      optionFilterProp="children"
                      // filterOption={(input, option) =>
                      //   option.children.toLowerCase().includes(input.toLowerCase())
                      // }
                      onSearch={(value) =>
                        getBrand(selectedBuyer, { name: value })
                      }
                      disabled={view}
                      size="small"
                      allowClear
                      onClear={() => setBrand([])}
                    >
                      {brand?.length &&
                        brand?.map((pi) => (
                          <Option value={pi?.id} key={pi?.id}>
                            {pi?.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
              </>
            )}

            <Col span={4}>
              <Form.Item
                label="Garments Category"
                name="garment_category_id"
                rules={[
                  { required: true, message: "Please input Garments Category" },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select a category"
                  optionFilterProp="children"
                  disabled={view}
                  size="small"
                  onSearch={(value) => getAllGarmentCategory({ name: value })}
                  allowClear
                >
                  {isArrayAndHasValue(garmentCategoryList) &&
                    garmentCategoryList?.map((categoryItem, index) => (
                      <Option
                        value={categoryItem?.id}
                        key={categoryItem?.id || index}
                      >
                        {categoryItem?.name || ""}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                label="Garments Type"
                name="garment_type_id"
                rules={[
                  { required: true, message: "Please input Garments Type" },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select a type"
                  optionFilterProp="children"
                  disabled={view}
                  size="small"
                  onSearch={(value) => getAllGarmentType({ name: value })}
                  allowClear
                >
                  {isArrayAndHasValue(garmentTypeList) &&
                    garmentTypeList?.map((typeItem, index) => (
                      <Option value={typeItem?.id} key={typeItem?.id || index}>
                        {typeItem?.name || ""}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                label="Garment For"
                name="garment_for_id"
                rules={[
                  { required: true, message: "Please input Garment For" },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select for"
                  optionFilterProp="children"
                  disabled={view}
                  size="small"
                  onSearch={(value) => getAllGarmentFor({ name: value })}
                  allowClear
                >
                  {isArrayAndHasValue(garmentForList) &&
                    garmentForList?.map((typeItem, index) => (
                      <Option value={typeItem?.id} key={typeItem?.id || index}>
                        {typeItem?.name || ""}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Description" name="description">
                <TextArea
                  placeholder="Description"
                  rows={2}
                  disabled={view}
                  size="small"
                  rules={[
                    {
                      required: true,
                      message: "Please Description. Ex-Type name",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                label="Knit / Woven"
                name="knit_woven"
                rules={[
                  { required: true, message: "Please input Knit / Woven" },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select Knit/Woven"
                  optionFilterProp="children"
                  disabled={view}
                  size="small"
                >
                  <Option value="Knit" key="knit">
                    Knit
                  </Option>
                  <Option value="Woven" key="woven">
                    Woven
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name="embroidery"
                placeholder="Embroidery"
                label="Embroidery"
                valuePropName="checked"
                initialValue={false}
                rules={[
                  { required: true, message: "Please input Embroidery" },
                ]}
              >
                <Switch checkedChildren="Active" unCheckedChildren="Inactive" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name="printing"
                placeholder="Printing"
                label="Printing"
                valuePropName="checked"
                initialValue={false}
                rules={[
                  { required: true, message: "Please input Printing" },
                ]}
              >
                <Switch checkedChildren="Active" unCheckedChildren="Inactive" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name="washing"
                placeholder="Washing"
                label="Washing"
                valuePropName="checked"
                initialValue={false}
                rules={[
                  { required: true, message: "Please input Washing" },
                ]}
              >
                <Switch checkedChildren="Active" unCheckedChildren="Inactive" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name="smoking"
                placeholder="Smoking"
                label="Smoking"
                valuePropName="checked"
                initialValue={false}
                rules={[
                  { required: true, message: "Please input Smoking" },
                ]}
              >
                <Switch checkedChildren="Active" unCheckedChildren="Inactive" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name="pintuck"
                placeholder="Pintuck"
                label="Pintuck"
                valuePropName="checked"
                initialValue={false}
                rules={[
                  { required: true, message: "Please input Pintuck" },
                ]}
              >
                <Switch checkedChildren="Active" unCheckedChildren="Inactive" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name="snap_button"
                placeholder="Snap Button"
                label="Snap Button"
                valuePropName="checked"
                initialValue={false}
                rules={[
                  { required: true, message: "Please input Pintuck" },
                ]}
              >
                <Switch checkedChildren="Active" unCheckedChildren="Inactive" />
              </Form.Item>
            </Col>

            <Col span={4}>
              <Form.Item
                name="status"
                placeholder="Status"
                label="Status"
                valuePropName="checked"
                initialValue={true}
                rules={[
                  { required: true, message: "Please input Status" },
                ]}
              >
                <Switch checkedChildren="Active" unCheckedChildren="Inactive" />
              </Form.Item>
            </Col>
          </Row>
        </Card>

        <div className="containt-body pt-3">
          <Card>
            <Row gutter={16}>
              <Col span={12}></Col>
              <Col span={6}></Col>
              <Col span={6}>
                {!view && (
                  <Button
                    type="primary"
                    style={{ float: "right" }}
                    onClick={tableHandeler}
                    size="small"
                  >
                    Add <b> +</b>
                  </Button>
                )}
              </Col>
            </Row>
            <Row gutter={16}>
              <Col className="gutter-row" span={8}>
                <Form.Item label="Color" name="color_id" disabled={true}>
                  <Select
                    showSearch
                    placeholder="Select a Color or Create"
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      return option?.label
                        ?.toLowerCase()
                        ?.includes(input?.toLowerCase());
                    }}
                    size="small"
                    dropdownMatchSelectWidth={false}
                    dropdownRender={(menu) => (
                      <>
                        {menu}
                        <Divider
                          style={{
                            margin: "8px 0",
                          }}
                        />
                        <Space
                          style={{
                            padding: "0 8px 4px",
                          }}
                        >
                          <Input
                            style={{ width: "100%" }}
                            placeholder="Enter New Color"
                            parser={(value) => {
                              return value.substring(0, 50);
                            }}
                            onChange={(e) => setColorName(e.target.value)}
                          />
                          <Button
                            type="text"
                            icon={<PlusOutlined />}
                            onClick={addColorItem}
                          >
                            Add Color
                          </Button>
                        </Space>
                      </>
                    )}
                    options={
                      colorsList?.length &&
                      colorsList.map((item) => ({
                        label: item?.name,
                        value: item?.id,
                      }))
                    }
                    disabled={view}
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={8}>
                <Form.Item
                  label="Sub Style No."
                  name="sub_style_no"
                  rules={[
                    {
                      required: false,
                      message: "Please input Sub Style!",
                    },
                  ]}
                  disabled={true}
                >
                  <Input disabled={view} size="small" />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </div>
      </Form>
      <SubStyle
        form={form}
        setTableState={setTableState}
        tableState={tableState}
        view={view}
      />
      <DocumentUpload
        DOC_ADD={STYLE_ADD_DOC}
        setFileList={setFileList}
        filesList={filesList}
        view={view}
        showType={true}
        showReference={true}
        preview={true}
        header={"Attachment *"}
      />

      <DocumentUpload
        filesList={documents}
        view={true}
        preview={true}
        showType={true}
        showReference={true}
      />
    </div>
  );
});

export default AddStyleForm;
