// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.appDashboard_appProductionDashboard__VWbC3 .appDashboard_ant-form-item__DwObG{
    margin-bottom: 0px !important;
}

.appDashboard_appProductionDashboard__VWbC3 .appDashboard_ant-input__jubIh {
    border: none;
    box-shadow: none; /* Remove any shadow if applied */
  }`, "",{"version":3,"sources":["webpack://./src/components/AppDashboard/appDashboard.module.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;AACjC;;AAEA;IACI,YAAY;IACZ,gBAAgB,EAAE,iCAAiC;EACrD","sourcesContent":[".appProductionDashboard .ant-form-item{\n    margin-bottom: 0px !important;\n}\n\n.appProductionDashboard .ant-input {\n    border: none;\n    box-shadow: none; /* Remove any shadow if applied */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"appProductionDashboard": `appDashboard_appProductionDashboard__VWbC3`,
	"ant-form-item": `appDashboard_ant-form-item__DwObG`,
	"ant-input": `appDashboard_ant-input__jubIh`
};
export default ___CSS_LOADER_EXPORT___;
