import React, { useCallback, useRef, useState } from "react";
import Header from "../../../Common/Header";
import { Card, Button, Breadcrumb, Affix, Form } from "antd";
import { isMobileView } from "../../../Common/AppHelper";
import { Link, useNavigate } from "react-router-dom";
import AddDetailsForm from "./AddDetailsForm";
import { debounce } from "lodash";

export default function AddIou(props) {
  const { permitInfo, width } = props;
  const propertyFormRef = useRef(null);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const discartForm = () => {
    // propertyFormRef.current.discart();
    navigate("/import/details");
  };

  const addIou = () => {
    // propertyFormRef.current.submit();
    form.submit();
  };

  const debouncedAddIou = useCallback(debounce(() => {
    addIou();
}, 1000), []); 

  return (
      <>
        <Affix offsetTop={0}>
        <Card
              className="p-fixed"
              style={{ width: `${isMobileView() ? "100%" : `${width}px`}` }}
          >
              <Header
                  title="Import Shipment Status"
                  subTitle=""
                  addLink="/"
                  addText="Add New"
                  actions={[
                    //   <Affix offsetTop={20}>
                    //       <Button
                    //           size="small"
                    //           key={1}
                    //           danger
                    //           onClick={() => discartForm()}
                    //       >
                    //           Discard
                    //       </Button>
                    //   </Affix>,
                      <Affix offsetTop={20}>
                          <Button
                              size="small"
                              key={2}
                              type="primary"
                              loading={loading}
                              onClick={debouncedAddIou}
                          >
                              Save
                          </Button>
                      </Affix>,
                  ]}
              />
          </Card>
        </Affix>
          <AddDetailsForm ref={propertyFormRef} form={form} setLoading={setLoading}/>
      </>
  );
}
