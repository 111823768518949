// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .header {
    background-color: #041527;
}

.header p {
    display: flex;
    color: rgba(255, 255, 255, 0.85);
    font-size: 14px;
    font-weight: bold;
    margin-left: 11px;
    margin-bottom: 0;
} */

.header {
    padding: 0 15px !important;
}

.header-notification-items {
    background-color: #FFFFFF;
    height: 80vh;
}

.header-notification-item {
    margin: 15px 0px 15px 0px;
    border: 1px solid lightgray;
    border-radius: 5px;
    padding: 5px;
    width: 500px;
}

.header-parsedStyle body {
    background-color: #e2e2e2; 
}

.header-noti-body {
    width: 500px;
}

.site-layout {
    overflow-y: scroll;
    height: 94vh !important;
}`, "",{"version":3,"sources":["webpack://./src/common/Header/index.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;GAWG;;AAEH;IACI,0BAA0B;AAC9B;;AAEA;IACI,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,yBAAyB;IACzB,2BAA2B;IAC3B,kBAAkB;IAClB,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,uBAAuB;AAC3B","sourcesContent":["/* .header {\n    background-color: #041527;\n}\n\n.header p {\n    display: flex;\n    color: rgba(255, 255, 255, 0.85);\n    font-size: 14px;\n    font-weight: bold;\n    margin-left: 11px;\n    margin-bottom: 0;\n} */\n\n.header {\n    padding: 0 15px !important;\n}\n\n.header-notification-items {\n    background-color: #FFFFFF;\n    height: 80vh;\n}\n\n.header-notification-item {\n    margin: 15px 0px 15px 0px;\n    border: 1px solid lightgray;\n    border-radius: 5px;\n    padding: 5px;\n    width: 500px;\n}\n\n.header-parsedStyle body {\n    background-color: #e2e2e2; \n}\n\n.header-noti-body {\n    width: 500px;\n}\n\n.site-layout {\n    overflow-y: scroll;\n    height: 94vh !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
