// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notification-items {
    background-color: #FFFFFF;
}

.notification-item {
    margin: 15px 0px 15px 0px;
    border: 1px solid lightgray;
    border-radius: 5px;
    padding: 10px;
}

.parsedStyle body {
    background-color: #e2e2e2; 
}`, "",{"version":3,"sources":["webpack://./src/components/Notifications/index.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,2BAA2B;IAC3B,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".notification-items {\n    background-color: #FFFFFF;\n}\n\n.notification-item {\n    margin: 15px 0px 15px 0px;\n    border: 1px solid lightgray;\n    border-radius: 5px;\n    padding: 10px;\n}\n\n.parsedStyle body {\n    background-color: #e2e2e2; \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
