// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-cell-ob {
    display: flex;
    justify-content: center;
    /* border-bottom: none; */
    /* border-top: none; */
    border-collapse: collapse;
    border: hidden;
}

.action-container {
    display: flex;
    justify-content: center;
    gap: 5px;
    align-items: center;
    padding: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/OperationBulletin/OBTable/index.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,yBAAyB;IACzB,sBAAsB;IACtB,yBAAyB;IACzB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,QAAQ;IACR,mBAAmB;IACnB,YAAY;AAChB","sourcesContent":[".add-cell-ob {\n    display: flex;\n    justify-content: center;\n    /* border-bottom: none; */\n    /* border-top: none; */\n    border-collapse: collapse;\n    border: hidden;\n}\n\n.action-container {\n    display: flex;\n    justify-content: center;\n    gap: 5px;\n    align-items: center;\n    padding: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
