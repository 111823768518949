// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-form label {
    font-size: 14px;
    margin-bottom: 10px;
}

.form-layout .form-layout-background {
    background: #fff;
    padding: 2px 24px 24px;
    margin-right: 5px;
    min-height: 60vh;
}

.item-details {
    background: #fff;
    padding: 2px 10px 2px 10px;
    margin-right: 5px;
    overflow-y: auto !important;
    height: 82vh !important;
}

.order-details {
    background: #fff;
    padding: 5px 20px 24px 24px;
    margin-right: 5px;
}

.abc {
    background: #a4dbb0;
}`, "",{"version":3,"sources":["webpack://./src/components/InventoryFabric/index.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,sBAAsB;IACtB,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,0BAA0B;IAC1B,iBAAiB;IACjB,2BAA2B;IAC3B,uBAAuB;AAC3B;;AAEA;IACI,gBAAgB;IAChB,2BAA2B;IAC3B,iBAAiB;AACrB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".ant-form label {\n    font-size: 14px;\n    margin-bottom: 10px;\n}\n\n.form-layout .form-layout-background {\n    background: #fff;\n    padding: 2px 24px 24px;\n    margin-right: 5px;\n    min-height: 60vh;\n}\n\n.item-details {\n    background: #fff;\n    padding: 2px 10px 2px 10px;\n    margin-right: 5px;\n    overflow-y: auto !important;\n    height: 82vh !important;\n}\n\n.order-details {\n    background: #fff;\n    padding: 5px 20px 24px 24px;\n    margin-right: 5px;\n}\n\n.abc {\n    background: #a4dbb0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
