import { useState } from "react";
import {
  Col,
  Input,
  DatePicker,
  Form,
  Row,
  Select,
  Collapse,
} from "antd";
import moment from "moment";
import { isArrayAndHasValue } from "../../../utils/functions";

const fromTemplatesData = [
  {
    id: 1,
    name: "OB Template",
    value: "ob_template",
  },
  {
    id: 2,
    name: "Inquiry",
    value: "inquiry",
  },
  {
    id: 3,
    name: "Style",
    value: "style",
  },
];

const OBForm = (props) => {
  // Props
  const {
    form,
    view,
    manpowerMode,
    obTemplates,
    getAllObTemplates,
    onSelectObTemplate,
    buyerInquiryList,
    getAllBuyerInquiry,
    obInquiryList,
    obStyleList,
    styleList,
    getStyles,
    setStyleList,
    getSubStyles,
    subStyleList,
    setSubStyleList,
    clearSections,
  } = props;

  // States
  const [selectedTemplateList, setSelectedTemplateList] = useState(null);

  // Antd
  const { Option } = Select;
  const style_id_watch = Form.useWatch("style_id", form);
  const inquiry_id_watch = Form.useWatch("inquiry_id", form);
  const subcontract_status_watch = Form.useWatch("subcontract_status", form);

  const onChangeStyle = (value, inquiry_id) => {
    const foundStyle = styleList?.find((style) => style.id === value);
    const subStyles = foundStyle?.sub_style;

    if(!inquiry_id_watch) {
      setSubStyleList(isArrayAndHasValue(subStyles) ? subStyles : []);
    }

    if(!foundStyle) return null;

    form.setFieldsValue({
      inquiry_id: inquiry_id || foundStyle?.style_inquiry_map?.inquiry_info?.id,
      buying_office_id: foundStyle?.buying_office_info?.id,
      buying_office_name: foundStyle?.buying_office_info?.name,
      buyer_id: foundStyle?.buyer_info?.id,
      buyer_name: foundStyle?.buyer_info?.name,
      sales_contract_id:
        foundStyle?.active_sales_contract_map_info?.active_sales_contract_info
          ?.id,
      sales_contract_no:
        foundStyle?.active_sales_contract_map_info?.active_sales_contract_info
          ?.reference_no,
      description: foundStyle?.description,
      sub_style_id: null,
      subcontract_status: foundStyle?.subcontract_status,
      sub_contract_factory_id: foundStyle?.factory_info?.id,
      factory_name: foundStyle?.factory_info?.name,
    });
  };

  const onChangeSubStyle = (value) => {
    const foundSubStyle = subStyleList?.find((item) => item?.id === value);
    const foundStyleId = foundSubStyle?.style_info?.id;
    const isExistsStyle = styleList?.find((item) => item?.id === foundStyleId);

    if(style_id_watch) {
      return;
    }

    if(!isExistsStyle) {
      const updatedStyleList = isArrayAndHasValue(styleList) ? [...styleList, foundSubStyle?.style_info] : [foundSubStyle?.style_info];
      setStyleList(updatedStyleList);
    }

    form.setFieldsValue({
      style_id: foundStyleId,
      buying_office_id: foundSubStyle?.style_info?.buying_office_info?.id,
      buying_office_name: foundSubStyle?.style_info?.buying_office_info?.name,
      buyer_id: foundSubStyle?.style_info?.buyer_info?.id,
      buyer_name: foundSubStyle?.style_info?.buyer_info?.name,
      sales_contract_id:
        foundSubStyle?.style_info?.active_sales_contract_map_info?.active_sales_contract_info
          ?.id,
      sales_contract_no:
        foundSubStyle?.style_info?.active_sales_contract_map_info?.active_sales_contract_info
          ?.reference_no,
      description: foundSubStyle?.style_info?.description,
      inquiry_id: foundSubStyle?.style_info?.inquiry_id,
      subcontract_status: foundSubStyle?.style_info?.subcontract_status,
      sub_contract_factory_id: foundSubStyle?.style_info?.factory_info?.id,
      factory_name: foundSubStyle?.style_info?.factory_info?.name,
    });
  }

  const onSelectBuyerInquiry = (value) => {
    const foundItem = buyerInquiryList?.find((item) => item.id === value);
    const styleId = foundItem?.style_inquiry_map?.style_info?.id;
    const styleObject = foundItem?.style_inquiry_map?.style_info;

    const subStyles = styleObject?.sub_style;
    setSubStyleList(isArrayAndHasValue(subStyles) ? [...subStyles] : []);

    const isExistsStyle = styleList?.find((item) => item?.id === styleObject?.id);
    
    if(!isExistsStyle && styleObject) {
      const updatedStyles = isArrayAndHasValue(styleList) ? [...styleList, styleObject] : [{styleObject}];
      setStyleList(updatedStyles);
    }

    if (styleId) {
      // Set style id in form
      form.setFieldsValue({
        style_id: styleId,
        description: foundItem?.description,
        subcontract_status: foundItem?.subcontract_status,
        sub_contract_factory_id: foundItem?.factory_info?.id,
        factory_name: foundItem?.factory_info?.name,
        buying_office_id: foundItem?.buying_office_info?.id,
        buying_office_name: foundItem?.buying_office_info?.name,
        buyer_id: foundItem?.buyer_info?.id,
        buyer_name: foundItem?.buyer_info?.name,
      });

      // Change other fields based on selected style
      onChangeStyle(styleId, value);
    } else {
      // Reset style id field
      form.resetFields(["style_id"]);

      // Set data to form fields
      form.setFieldsValue({
        buying_office_id: foundItem?.buying_office_info?.id,
        buying_office_name: foundItem?.buying_office_info?.name,
        buyer_id: foundItem?.buyer_info?.id,
        buyer_name: foundItem?.buyer_info?.name,
        description: foundItem?.description,
        subcontract_status: foundItem?.subcontract_status,
        sub_contract_factory_id: foundItem?.factory_info?.id,
        factory_name: foundItem?.factory_info?.name,
      });
    }
  };

  // Custom validation rule to check that either buyer inquiry or style is selected
  const validateBuyerInquiryOrStyle = (rule, value) => {
    if (!value && !form.getFieldValue("inquiry_id")) {
      return Promise.reject("Please select either Buyer Inquiry or Style.");
    }
    return Promise.resolve();
  };

  const onSelectSource = (value) => {
    switch(value) {
      case 'ob_template':
        setSelectedTemplateList(null);
        form.resetFields(["ob_template_id"]);
        setSelectedTemplateList(obTemplates);
        break;
      case 'inquiry':
        setSelectedTemplateList(null);
        form.resetFields(["ob_template_id"]);
        setSelectedTemplateList(obInquiryList);
        break;
      case 'style':
        setSelectedTemplateList(null);
        form.resetFields(["ob_template_id"]);
        setSelectedTemplateList(obStyleList);
        break;
      default:
        break;
    }
  }

  const getOBTemplateValue = (item) => {
    const from_value = form.getFieldValue("from_template_name");

    switch(from_value) {
      case 'ob_template':
        return item?.id;
      case 'inquiry':
        return item?.operation_bulletin?.id;
      case 'style':
        return item?.operation_bulletin?.id;
      default:
        break;
    }
  }

  return (
    <>
      <Collapse defaultActiveKey={["1"]} bordered style={{ marginTop: 15 }}>
        <Collapse.Panel header={<b>OB Details</b>} key="1">
          <Row gutter={6}>
            <Col span={4}>
              <Form.Item label="Inquiry No." name="inquiry_id">
                <Select
                  showSearch
                  placeholder="Select buyer inquiry"
                  optionFilterProp="children"
                  disabled={view || manpowerMode}
                  size="small"
                  onSearch={(value) => getAllBuyerInquiry({ name: value })}
                  allowClear
                  onSelect={(value) => onSelectBuyerInquiry(value)}
                  onClear={getAllBuyerInquiry}
                >
                  {isArrayAndHasValue(buyerInquiryList) &&
                    buyerInquiryList?.map((inquiryItem, index) => (
                      <Option
                        value={inquiryItem?.id}
                        key={inquiryItem?.id || index}
                      >
                        {inquiryItem?.inquiry_no || ""}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                label="Style No"
                name="style_id"
                rules={[
                  {
                    validator: validateBuyerInquiryOrStyle,
                  },
                ]}
              >
                <Select
                  onSelect={(value) => onChangeStyle(value)}
                  onSearch={(value) => getStyles({ style_no: value })}
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  size="small"
                  disabled={view || manpowerMode}
                  allowClear
                  onClear={getStyles}
                >
                  {isArrayAndHasValue(styleList) &&
                    styleList?.map((style, idx) => (
                      <Option
                        key={idx}
                        value={style?.id}
                        label={style?.style_no}
                        // disabled={style?.operation_bulletin}
                      >
                        {`${style?.style_no || ""}${
                          style?.description ? ":" : ""
                        }${style?.description || ""}`}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Sub Style" name="sub_style_id">
                <Select
                  onSelect={(value) => onChangeSubStyle(value)}
                  onSearch={(value) => getSubStyles({ sub_style_no: value })}
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  size="small"
                  disabled={view || manpowerMode}
                  allowClear
                  onClear={getSubStyles}
                >
                  {isArrayAndHasValue(subStyleList) &&
                    subStyleList?.map((item, index) => (
                      <Option
                        key={item?.id}
                        value={item?.id}
                        label={item?.sub_style_no}
                      >
                        {`${item?.color_info?.name || "N/A"} ${
                          item?.sub_style_no ? `(${item?.sub_style_no})` : ""
                        } `}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={subcontract_status_watch ? 4 : 0}>
              <Form.Item name="subcontract_status" hidden />
              <Form.Item name="sub_contract_factory_id" hidden />

              {subcontract_status_watch && (
                <Form.Item label="Factory" name="factory_name">
                  <Input
                    placeholder="Factory Name"
                    disabled={true}
                    size="small"
                  />
                </Form.Item>
              )}
            </Col>
            <Col span={4}>
              <Form.Item label="Description" name="description">
                <Input placeholder="Description" disabled={true} size="small" />
              </Form.Item>
            </Col>
            <Col span={subcontract_status_watch ? 0 : 4}>
              <Form.Item name="buying_office_id" hidden />
              <Form.Item label="Buying Office" name="buying_office_name" hidden={subcontract_status_watch ? true : false}>
                <Input
                  disabled={true}
                  placeholder="Select Style First"
                  size="small"
                />
              </Form.Item>
            </Col>
            <Col span={subcontract_status_watch ? 0 : 4}>
              <Form.Item name="buyer_id" hidden />
              <Form.Item label="Buyer" name="buyer_name" hidden={subcontract_status_watch ? true : false}>
                <Input
                  name="buyer_name"
                  placeholder="Select Style First"
                  disabled={true}
                  size="small"
                />
              </Form.Item>
            </Col>
            <Col span={subcontract_status_watch ? 0 : 4}>
              <Form.Item name="sales_contract_id" hidden />
              <Form.Item label="Sales Contract" name="sales_contract_no" hidden={subcontract_status_watch ? true : false}>
                <Input
                  placeholder="Select Style First"
                  disabled={true}
                  size="small"
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                label="Entry Date"
                name="entry_date"
                rules={[
                  {
                    required: true,
                    message: `Entry Date is required `,
                  },
                ]}
                initialValue={moment()}
              >
                <DatePicker
                  format="YYYY-MM-DD"
                  style={{
                    width: "100%",
                  }}
                  disabled={view || manpowerMode}
                  align="right"
                  size="small"
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label="Note" name="note">
                <Input.TextArea rows={1} placeholder="Note" />
              </Form.Item>
            </Col>
          </Row>
        </Collapse.Panel>
      </Collapse>
      <Collapse defaultActiveKey={["2"]} bordered style={{ marginTop: 15 }}>
        <Collapse.Panel header={<b>Copy from Previous OB</b>} key="2">
          <Row gutter={6}>
            {!view && (
              <>
                <Col span={8}>
                  <Form.Item label="From" name="from_template_name">
                    <Select
                      onSelect={(value) => onSelectSource(value)}
                      style={{
                        width: "100%",
                      }}
                      placeholder="Select One"
                      optionFilterProp="children"
                      size="small"
                      disabled={manpowerMode}
                    >
                      {fromTemplatesData?.map((item, index) => (
                        <Option
                          key={item?.id}
                          value={item?.value}
                          label={item?.name}
                        >
                          {item?.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="OB Templates" name="ob_template_id">
                    <Select
                      onSelect={(value) => onSelectObTemplate(value)}
                      onSearch={(value) => getAllObTemplates({ name: value })}
                      showSearch
                      style={{
                        width: "100%",
                      }}
                      placeholder="Search"
                      optionFilterProp="children"
                      size="small"
                      disabled={manpowerMode}
                      allowClear
                      onClear={clearSections}
                    >
                      {isArrayAndHasValue(selectedTemplateList) &&
                        selectedTemplateList?.map((item, index) => (
                          <Option
                            key={item?.id}
                            value={getOBTemplateValue(item)}
                            label={item?.template_name}
                          >
                            {item?.template_name ||
                              item?.inquiry_no ||
                              item?.style_no}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
              </>
            )}
          </Row>
        </Collapse.Panel>
      </Collapse>
    </>
  );
};

export default OBForm;
