// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-container {
  overflow: auto;
  max-height: 650px;
}

.table-container table {
  width: inherit!important;
  border-collapse: collapse;
  overflow-x: scroll;
  overflow-y: scroll;
}

.table-container th,
.table-container td {
  padding: 8px;
  text-align: left;
}

.table-container th{
  border-collapse: collapse;
}

.bc-white {
      background: #fff;
      position: sticky!important;
      top: 0;
      z-index: 1;
  }


.bc-java {
    background: #cdffcd;
    position: sticky!important;
    top: 0;
    z-index: 1;
}


.bc-orange {
      background: #e79806;
      position: sticky!important;
      top: 0;
      z-index: 1;
  }


.bc-light-orange {
      background: #ffecc8;
      position: sticky!important;
      top: 0;
      z-index: 1;
  }


.bc-light-ver {
      background: #bd72f7;
      position: sticky!important;
      top: 0;
      z-index: 1;
  }


.bc-light-blue {
      background: #39dbed;
      position: sticky!important;
      top: 0;
      z-index: 1;
  }

/* Rest of your styles */
`, "",{"version":3,"sources":["webpack://./src/components/PurchaseOrderNew/AddOrUpdateStyle/POTable.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,wBAAwB;EACxB,yBAAyB;EACzB,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;;EAEE,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;MACM,gBAAgB;MAChB,0BAA0B;MAC1B,MAAM;MACN,UAAU;EACd;;;AAGF;IACI,mBAAmB;IACnB,0BAA0B;IAC1B,MAAM;IACN,UAAU;AACd;;;AAGA;MACM,mBAAmB;MACnB,0BAA0B;MAC1B,MAAM;MACN,UAAU;EACd;;;AAGF;MACM,mBAAmB;MACnB,0BAA0B;MAC1B,MAAM;MACN,UAAU;EACd;;;AAGF;MACM,mBAAmB;MACnB,0BAA0B;MAC1B,MAAM;MACN,UAAU;EACd;;;AAGF;MACM,mBAAmB;MACnB,0BAA0B;MAC1B,MAAM;MACN,UAAU;EACd;;AAEF,wBAAwB","sourcesContent":[".table-container {\n  overflow: auto;\n  max-height: 650px;\n}\n\n.table-container table {\n  width: inherit!important;\n  border-collapse: collapse;\n  overflow-x: scroll;\n  overflow-y: scroll;\n}\n\n.table-container th,\n.table-container td {\n  padding: 8px;\n  text-align: left;\n}\n\n.table-container th{\n  border-collapse: collapse;\n}\n\n.bc-white {\n      background: #fff;\n      position: sticky!important;\n      top: 0;\n      z-index: 1;\n  }\n\n\n.bc-java {\n    background: #cdffcd;\n    position: sticky!important;\n    top: 0;\n    z-index: 1;\n}\n\n\n.bc-orange {\n      background: #e79806;\n      position: sticky!important;\n      top: 0;\n      z-index: 1;\n  }\n\n\n.bc-light-orange {\n      background: #ffecc8;\n      position: sticky!important;\n      top: 0;\n      z-index: 1;\n  }\n\n\n.bc-light-ver {\n      background: #bd72f7;\n      position: sticky!important;\n      top: 0;\n      z-index: 1;\n  }\n\n\n.bc-light-blue {\n      background: #39dbed;\n      position: sticky!important;\n      top: 0;\n      z-index: 1;\n  }\n\n/* Rest of your styles */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
