// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.file-input-label {
    background-color: #2595FB;
    color: white;
    padding: 0.2rem;
    font-family: sans-serif;
    border-radius: 0.1rem;
    cursor: pointer;
    margin-top: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Common/DocumentUpload/custom_input.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,YAAY;IACZ,eAAe;IACf,uBAAuB;IACvB,qBAAqB;IACrB,eAAe;IACf,gBAAgB;AACpB","sourcesContent":[".file-input-label {\n    background-color: #2595FB;\n    color: white;\n    padding: 0.2rem;\n    font-family: sans-serif;\n    border-radius: 0.1rem;\n    cursor: pointer;\n    margin-top: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
