// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-card {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 20px 10px;
    gap: 18px;
    width: 382.67px;
    height: 96px;
}

`, "",{"version":3,"sources":["webpack://./src/components/Dashboard/index.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,SAAS;IACT,eAAe;IACf,YAAY;AAChB","sourcesContent":[".dashboard-card {\n    display: flex;\n    flex-direction: row;\n    align-items: flex-start;\n    padding: 20px 10px;\n    gap: 18px;\n    width: 382.67px;\n    height: 96px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
