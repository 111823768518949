import React, { forwardRef, useState, useEffect, useRef, useCallback } from "react";
import { useParams } from "react-router";
import moment from "moment";
import {
  Button,
  Card,
  DatePicker,
  InputNumber,
  Row,
  Col,
  Form,
  Input,
  Select,
  Table,
  Space,
  Modal,
  Divider

} from "antd";
import { FilePdfOutlined, MinusOutlined, PlusOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import {
  IMPORT_B2B_LC_ADD,
  IMPORT_B2B_LC_EDIT,
  COMMERCIAL_SALES_CONTRACT_LIST,
  ACCEPTANCE_FROMS,
  ACCEPTANCE_FROM_ADD,
  IMPORT_COVER_NOT,
  IMPORT_COVER_NOT_ADD,
} from "../../../../apiServices/API_ENDPOINTS";
import { postData, getData } from "../../../../apiServices/common";
import { alertPop } from "../../../../apiServices/common/helper";
import HandleNotification from "../../../../common/Notification";
import { commaSeparateNumber, isArrayAndHasValue } from "../../../../utils/functions";
import B2bAmendmentTable from "./B2bAmendmentTable";

const { confirm } = Modal;

const AddExportSalesContractForm = forwardRef((props, refs) => {
  // Props
  const { permitInfo, view, form } = props;

  const { importId } = useParams();
  const initialLCDetails = {
    unique_id: 1,
    import_b2blc_id: importId ? Number(importId) : null,
    amendment_number: null,
    amendment_date: null,
    amendment_value: null,
    amendment_quantity: null,
    remarks: [],
    insurance_cover_note: null,
    lc_amendment_file: null,
  };

  //for Acceptance form
  const [addedFrom, setAddedFrom] = useState(null);
  const [froms, setFroms] = useState(null);
  const inputRef = useRef(null);

  const [isDescription, setIsDescription] = useState(false);
  const [isDeferred, setIsDeferred] = useState(false);
  const [supplier, setSupplier] = useState([]);
  const [salesContractList, setSalesContractList] = useState([]);
  const [piNumbersList, setPiNumberList] = useState([]);
  const [piNumbersArray, setPiNumberArray] = useState([]);
  const [totalB2BLcValue, setB2BLcValue] = useState(0);
  const [totalB2BLcQuantity, setB2BLcQuantity] = useState(0);
  const [filesList, setFileList] = useState([]);
  const [isEdit, setIsEdit] = useState(0);
  const [paymentTermDeferred, setPaymentTermDeferred] = useState();
  const [fromDate, setFromDate] = useState();
  const [selectedPiNumbers, setSelectedPiNumbers] = useState([]);
  const [balanceValue, setBalanceValue] = useState(0);
  const [balanceQuantity, setBalanceQuantity] = useState(0);
  const [showInsurenceCover, setShowInsurenceCover] = useState(true);
  const [hide, setHide] = useState(false);
  const [SupplierType, setSupplierType] = useState(null);
  const [amendmentDetails, setAmendmentDetails] = useState([
    {
      id: 1,
      amendment_number: "",
      amendment_date: "",
      amendment_quantity: "",
      amendment_value: "",
      remarks: "",
      insurance_cover_note: "",
      lc_amendment_file: "",
    },
  ]);
  const [piListOnEdit, setPiListOnEdit] = useState([]);
  const [currentPiList, setCurrentPiList] = useState([]);
  const [lcdetailsList, setLcdetailsList] = useState([initialLCDetails]);
  const [withShowPaymentTerms, setWithShowPaymentTerms] = useState(true);

  // Antd Constants
  const { Option } = Select;

  // Others

  const description = [
    { id: 1, name: "Fabric" },
    { id: 2, name: "Accessories" },
    { id: 3, name: "Fabric & Accessories" },
  ];

  const paymentTerms = [
    { id: 1, name: "Deferred" },
    { id: 2, name: "Sight" },
    { id: 3, name: "TT" },
  ];
  const paymentTermsDeferred = [
    { id: 1, value: 30 },
    { id: 2, value: 60 },
    { id: 3, value: 90 },
    { id: 4, value: 120 },
    { id: 5, value: 150 },
    { id: 6, value: 180 },
  ];
  var document = [];
  var document_name = [];
  const api_base_url = process.env.REACT_APP_BASE_URL;

  // Effects
  useEffect(() => {
    // Get sales contract on first load
    getFromsList();
    getSalesContractList();
  }, []);

  useEffect(() => {
    // Get PI numbers list
    // Set current PI List
    if (permitInfo) {
      const sales_contract_commercial_value =
        permitInfo?.sales_contract_commercial;
      const currentPi = sales_contract_commercial_value?.pi_relations?.map(
        (item, index) => {
          return {
            id: item?.import_pi_id,
            pi_number: item?.invoice_number,
            pi_date: item?.invoice_date,
            items_value: item?.invoice_value,
            items_quantity: item?.invoice_quantity,
            invoice_unit: item?.invoice_unit,
            isCurrent: true,
            pi_document: item?.pi_document ?? null,
          };
        },
      );
      getPiNumbersList(sales_contract_commercial_value?.supplier_id, sales_contract_commercial_value?.sales_contract_id, 1);
      setCurrentPiList(currentPi);
      paymentTypeChange(sales_contract_commercial_value?.payment_type)

      setFileList(
        permitInfo?.sales_contract_commercial?.b2b_lc_document.map(
          (item) => {
            return {
              id: item?.id,
              name: item?.document_name,
              document: item?.document,
              // name: file.name,
              path: item?.document,
            };
          },
        ),
      );
    }
  }, [permitInfo]);

  useEffect(() => {
    // Merge current PI list with PI list on edit
    if (piListOnEdit && isEdit) {
      const mergedPiList = [...currentPiList, ...piListOnEdit];
      setPiNumberList(mergedPiList);
    }
  }, [piListOnEdit, currentPiList, isEdit]);

  useEffect(() => {
    if (permitInfo && form) {
      const sales_contract_commercial_value =
        permitInfo?.sales_contract_commercial;

      getSupplierList(permitInfo?.sales_contract_commercial?.sales_contract_id);
      setSupplierType(permitInfo?.sales_contract_commercial?.supplier_type);
      const formattedLCDetailsData = isArrayAndHasValue(sales_contract_commercial_value?.amendments) && sales_contract_commercial_value?.amendments?.map((item, index) => {
        return {
          ...item,
          amendment_date: item?.amendment_date
            ? moment(item?.amendment_date, "YYYY-MM-DD")
            : null,
          lc_amendment_file: {
            id: item?.amendment_document_info?.id,
            document_name: item?.amendment_document_info?.document_name,
            document: item?.amendment_document_info?.document,
          },
          unique_id: index + 1,
          remarks: isArrayAndHasValue(item?.remarks)
            ? item?.remarks?.map((item) => item?.relations?.id)
            : [],
          insurance_cover_note: item?.insurance_cover_note ? item?.insurance_cover_note:null,
        };
      });
      setLcdetailsList(formattedLCDetailsData);

      const pi_number_array = sales_contract_commercial_value?.pi_number
        ? sales_contract_commercial_value?.pi_number.split(",")
        : [];
      const pi_number = [];

      pi_number_array &&
        pi_number_array.length > 0 &&
        pi_number_array.forEach((item, index) => {
          pi_number.push(Number(item));
          piNumbersArray.push(Number(item));
        });

      setPiNumberArray(piNumbersArray);
      setIsEdit(1);

      const import_lc_insurance_cover_note = permitInfo?.sales_contract_commercial?.import_lc_insurance_cover_note || [];
      const insuranceField = import_lc_insurance_cover_note.map(item => (item.insurance_cover_note.id));

      setIsDeferred((permitInfo?.sales_contract_commercial?.terms_of_payment === "Deferred") ? 1 : null);

      setIsDescription((permitInfo?.sales_contract_commercial?.description === 3) ? 1 : null);

      setPaymentTermDeferred(permitInfo?.sales_contract_commercial?.payment_term_deferred);
      setFromDate(permitInfo?.sales_contract_commercial?.from_date);
      setSelectedPiNumbers(pi_number);
      setB2BLcValue(permitInfo?.sales_contract_commercial?.total_lc_value);
      setB2BLcQuantity(
        permitInfo?.sales_contract_commercial?.total_lc_quantity,
      );
      setBalanceValue(permitInfo?.sales_contract_commercial?.balance_value);
      setBalanceQuantity(
        permitInfo?.sales_contract_commercial?.balance_quantity,
      );

      form.setFieldsValue({
        ...sales_contract_commercial_value,
        cover_noted_number: insuranceField,
        supplier_id: permitInfo?.sales_contract_commercial?.supplier_id,
        total_lc_value: permitInfo?.sales_contract_commercial?.total_lc_value,
        total_lc_quantity:
          permitInfo?.sales_contract_commercial?.total_lc_quantity,
        pi_number: pi_number,
        b2b_lc_date:
          sales_contract_commercial_value.b2b_lc_date != null
            ? moment(sales_contract_commercial_value.b2b_lc_date, "YYYY-MM-DD")
            : null,
        b2b_lc_amendment_date:
          sales_contract_commercial_value.b2b_lc_amendment_date != null
            ? moment(
              sales_contract_commercial_value.b2b_lc_amendment_date,
              "YYYY-MM-DD",
            )
            : null,
        shipment_date:
          sales_contract_commercial_value.shipment_date != null
            ? moment(
              sales_contract_commercial_value.shipment_date,
              "YYYY-MM-DD",
            )
            : null,
        expiry_date:
          sales_contract_commercial_value.expiry_date != null
            ? moment(sales_contract_commercial_value.expiry_date, "YYYY-MM-DD")
            : null,
        payment_term_deferred:
          permitInfo?.sales_contract_commercial?.payment_term_deferred,
        // from_date:
        //   permitInfo?.sales_contract_commercial?.from_date,
        from_date: permitInfo?.sales_contract_commercial?.froms_value,
      });

      setShowInsurenceCover((form.getFieldValue('supplier_type') === "Foreign") ? true : false)
    } else {
      form.setFieldsValue({
        total_lc_value: totalB2BLcValue,
        total_lc_quantity: totalB2BLcQuantity,
        balance_value: totalB2BLcValue,
        balance_quantity: totalB2BLcQuantity,
      });
    }
  }, [permitInfo]); // form, totalB2BLcValue, totalB2BLcQuantity

  const onSelectPaymentTerm = (value) => {
    if (value === "Deferred") {
      setIsDeferred(true);
    } else {
      setIsDeferred(false);
    }
  };

  const onSelectDescriptionValue = (value) => {

    if (value === 3) {
      setIsDescription(true);
    } else {
      setIsDescription(false);
    }
  };

  const onFinishSalesContract = async (values) => {
    //  return;
    if (filesList?.length) {
      filesList.forEach((item) => {
        document_name.push(item?.name);
        document.push(item?.id);
      });
    }

    const addedPiList = piNumbersList.filter((item) => item?.isCurrent);

    const piWithUnit = addedPiList.map((item) => {
      return {
        ...item,
        unit: "Yds",
      };
    });

    const formattedAmend =
      isArrayAndHasValue(lcdetailsList) &&
      lcdetailsList?.map((item) => {
        return {
          import_b2blc_id: item?.import_b2blc_id
            ? Number(item?.import_b2blc_id)
            : Number(importId),
          unique_id: item?.unique_id || null,
          amendment_number: item?.amendment_number || null,
          amendment_date: item?.amendment_date
            ? moment(item?.amendment_date).format("YYYY-MM-DD")
            : null,
          amendment_value: item?.amendment_value
            ? Number(item?.amendment_value)
            : null,
          amendment_quantity: item?.amendment_quantity
            ? Number(item?.amendment_quantity)
            : null,
          remarks: item?.remarks || null,
          insurance_cover_note: item?.insurance_cover_note || null,
          lc_amendment_file: item?.lc_amendment_file?.id
            ? Number(item?.lc_amendment_file?.id)
            : null,
        };
      });

    const bodyData = {
      ...values,
      document,
      document_name,
      ...(permitInfo && { id: permitInfo?.sales_contract_commercial?.id }),
      pi_data: piWithUnit,
      amendmentDetails: formattedAmend,
    };

    if (bodyData?.id > 0) {
      let response = await postData(
        IMPORT_B2B_LC_EDIT + "/" + bodyData?.id,
        bodyData,
      );

      if (response) {
        alertPop("success", "B2B LC Updated Successfully");
      }
    } else {
      let response = await postData(IMPORT_B2B_LC_ADD, bodyData);

      if (response) {
        alertPop("success", "B2B LC Added Successfully");
      }
    }
  };

  const getSalesContractList = async () => {
    let res = await getData(COMMERCIAL_SALES_CONTRACT_LIST);

    if (res) {
      setSalesContractList(res?.data?.data || []);
    }
  };

  const getSupplierList = async (value) => {
    setShowInsurenceCover(true)
    form.resetFields(["supplier_id", "supplier_type"]);
    let get_supplier_list = `/api/accounting/supplier-list/${value}`;
    let res = await getData(get_supplier_list);
    if (res) {
      setSupplier(res?.data?.data || []);
    }
  };



  const handleShowHideInsurence = (value) => {
    const localStatus = supplier.find(obj => obj.id === value)?.local_status || null;
    setShowInsurenceCover(localStatus === 0 ? true : false)
  }

  const getPiNumbersList = async (value, isEdit) => {
    const sales_contract_id = permitInfo
      ? permitInfo?.sales_contract_commercial?.sales_contract_id
      : form.getFieldValue("sales_contract_id");
    let get_pi_list = `/api/accounting/pi-numbers-list/${value}/${sales_contract_id}/${isEdit}`;
    let res = await getData(get_pi_list);


    if (res) {
      if (isEdit) {
        setPiListOnEdit(res?.data?.data || []);
      } else {
        setPiNumberList(res?.data?.data || []);
      }
    }
  };

  const getSupplierType = async (value, isEdit) => {

    let get_supplier = `/api/suppliers/${value}/edit`;
    let res = await getData(get_supplier);

    let supplier_type = "Local";
    if (res?.data?.data?.local_status === 0)
      supplier_type = "Foreign";
    else if (res?.data?.data?.local_status === 1)
      supplier_type = "Local";
    else
      supplier_type = "EPZ";

    setSupplierType(supplier_type || null);

    form.setFieldsValue({
      supplier_type: supplier_type,
    });
  };


  const addPI = (pi_id) => {
    const piNumbersListCopy = [...piNumbersList];
    const selectedPi = piNumbersListCopy.find((item) => item.id === pi_id);
    selectedPi.isCurrent = true;
    setPiNumberList(piNumbersListCopy);

    // Add to existing values
    const totalBTBLCValue = form.getFieldValue("total_lc_value");
    const totalBTBLCQuantity = form.getFieldValue("total_lc_quantity");
    const itemsValue = selectedPi?.items_value;
    const itemsQuantity = selectedPi?.items_quantity;
    const updatedBTBLCValue = totalBTBLCValue + Number(itemsValue);
    const updatedBTBLCQuantity = totalBTBLCQuantity + Number(itemsQuantity);

    // setB2BLcValue(updatedBTBLCValue);
    // setB2BLcQuantity(updatedBTBLCQuantity);
    // Set to form values
    form.setFieldsValue({
      total_lc_value: updatedBTBLCValue,
      total_lc_quantity: updatedBTBLCQuantity,
      balance_value: updatedBTBLCValue,
      balance_quantity: updatedBTBLCQuantity,
    });

    console.log('Type of amendment_date before:', typeof lcdetailsList[0]?.amendment_date);
console.log('Type of pi_date:', typeof selectedPi.pi_date);
    
    
    // fill up amendment_date,amendment_value, amendment_quantity from selected pi in lcdetailsList by adding new row, if lcdetailsList has one row and amendment_number is null then update that item with value otherwise create new row for lcdetailsList and amendment_number will be like `AMENDMENT ${lcDetailsListCopy.length}`
    if (lcdetailsList.length === 1 && !lcdetailsList[0].amendment_number) {
      const lcDetailsListCopy = [...lcdetailsList];
      lcDetailsListCopy[0].amendment_value = selectedPi?.items_value;
      lcDetailsListCopy[0].amendment_quantity = selectedPi?.items_quantity;
      setLcdetailsList(lcDetailsListCopy);
    } else {
      // add a new row with selectedPi value for corresponding fields
      const lcDetailsListCopy = [...lcdetailsList];
      const newData = {
        ...initialLCDetails,
        unique_id: isArrayAndHasValue(lcDetailsListCopy)
          ? lcDetailsListCopy[lcDetailsListCopy.length - 1].unique_id + 1
          : 0,
        amendment_number: `AMENDMENT ${lcDetailsListCopy.length}`,
        amendment_value: selectedPi?.items_value,
        amendment_quantity: selectedPi?.items_quantity,
        piId: selectedPi?.id ?? null
      };
      lcDetailsListCopy.push(newData);
      setLcdetailsList(lcDetailsListCopy);
    }
  };

  const removePI = (pi_id) => {

    const piNumbersListCopy = [...piNumbersList];
    const selectedPi = piNumbersListCopy.find((item) => item.id === pi_id);

    confirm({
      title: "Do you Want to remove this PI?",
      icon: <ExclamationCircleOutlined />,
      // content: 'Some descriptions',
      onOk() {
        let deletePI = getData(`/api/accounting/delete-pi-from-b2blc/${pi_id}`);

      },
      onCancel() {
        console.log("Cancel");
      },
    });


    selectedPi.isCurrent = false;
    setPiNumberList(piNumbersListCopy);

    // Add to existing values
    const totalBTBLCValue = form.getFieldValue("total_lc_value");
    const totalBTBLCQuantity = form.getFieldValue("total_lc_quantity");
    const itemsValue = selectedPi?.items_value;
    const itemsQuantity = selectedPi?.items_quantity;
    const updatedBTBLCValue = totalBTBLCValue ? totalBTBLCValue - Number(itemsValue) : 0;
    const updatedBTBLCQuantity = totalBTBLCQuantity ? totalBTBLCQuantity - Number(itemsQuantity) : 0;

    // setB2BLcValue(updatedBTBLCValue);
    // setB2BLcQuantity(updatedBTBLCQuantity);
    // Set to form values
    form.setFieldsValue({
      total_lc_value: updatedBTBLCValue,
      total_lc_quantity: updatedBTBLCQuantity,
      balance_value: updatedBTBLCValue,
      balance_quantity: updatedBTBLCQuantity,
    });

    // remove the value from lcdetailsList, remove the corresponding row not other rows, if lcdetailsList has more than one row.. if lcdetailsList has one row then just make values null, don't remove that item
    if (lcdetailsList.length > 1) {
      const lcDetailsListCopy = [...lcdetailsList];
      const index = lcDetailsListCopy.findIndex((item) => item.piId === pi_id);
      if (index > -1) {
        lcDetailsListCopy.splice(index, 1);
        setLcdetailsList(lcDetailsListCopy);
      }
    } else {
      const lcDetailsListCopy = [...lcdetailsList]; 
      lcDetailsListCopy[0].amendment_value = null;
      lcDetailsListCopy[0].amendment_quantity = null;
      setLcdetailsList(lcDetailsListCopy);
    }
    
  };

  const addRowOld = () => {
    setAmendmentDetails([
      ...amendmentDetails,
      {
        id:
          amendmentDetails.length > 0
            ? amendmentDetails[amendmentDetails.length - 1]?.id + 1
            : 1,
        amendment_number: "",
        amendment_date: "",
        amendment_quantity: "",
        amendment_value: "",
        remarks: "",
        lc_amendment_file: "",
      },
    ]);
  };

  const addRow = (unique_id) => {
    const lcDetailsListCopy = isArrayAndHasValue(lcdetailsList)
      ? [...lcdetailsList]
      : [];
    const newData = {
      ...initialLCDetails,
      unique_id: isArrayAndHasValue(lcDetailsListCopy)
        ? lcDetailsListCopy[lcDetailsListCopy.length - 1].unique_id + 1
        : 0,
      amendment_number: `AMENDMENT ${lcDetailsListCopy.length}`,
    };
    lcDetailsListCopy.push(newData);
    setLcdetailsList(lcDetailsListCopy);
  };

  const removeRow = (id) => {
    const amendmentDetailsCopy = [...amendmentDetails];
    const filteredAmendmentDetails = amendmentDetailsCopy.filter(
      (item) => item.id !== id,
    );
    setAmendmentDetails(filteredAmendmentDetails);
  };

  const onChangeAmendment = (value, id, key) => {
    const amendmentDetailsCopy = [...amendmentDetails];
    const index = amendmentDetailsCopy.findIndex((item) => item.id === id);
    amendmentDetailsCopy[index][key] = value;
    setAmendmentDetails(amendmentDetailsCopy);
  };

  const amendmentColumns = [
    {
      title: "Initial/Amendment",
      dataIndex: "amendment_number",
      key: "amendment_number",
      render: (text, record, index) => {
        return (
          <Input
            placeholder="Initial/Amendment"
            onChange={(e) =>
              onChangeAmendment(e.target.value, record.id, "amendment_number")
            }
            value={text}
          />
        );
      },
    },
    {
      title: "LC/Amendment Date",
      dataIndex: "amendment_date",
      key: "amendment_date",
      render: (text, record, index) => {
        return (
          <DatePicker
            placeholder="Amendment Date"
            onChange={(date, dateString) =>
              onChangeAmendment(dateString, record.id, "amendment_date")
            }
            value={text ? moment(text, "YYYY-MM-DD") : null}
          />
        );
      },
    },
    {
      title: "LC Quantity",
      dataIndex: "amendment_quantity",
      key: "amendment_quantity",
      render: (text, record, index) => {
        return (
          <Input
            placeholder="Amendment Quantity"
            onChange={(e) =>
              onChangeAmendment(e.target.value, record.id, "amendment_quantity")
            }
            value={text}
          />
        );
      },
    },
    {
      title: "LC Value",
      dataIndex: "amendment_value",
      key: "amendment_value",
      render: (text, record, index) => {
        return (
          <Input
            placeholder="LC Value"
            onChange={(e) =>
              onChangeAmendment(e.target.value, record.id, "amendment_value")
            }
            value={text}
          />
        );
      },
    },
    {
      title: "Reason of Amendment",
      dataIndex: "remarks",
      key: "remarks",
      render: (text, record, index) => {
        return (
          <Input
            placeholder="Reason of Amendment"
            onChange={(e) =>
              onChangeAmendment(e.target.value, record.id, "remarks")
            }
            value={text}
          />
        );
      },
    },
    {
      title: "Document",
      dataIndex: "lc_amendment_file",
      key: "lc_amendment_file",
      render: (text, record, index) => {
        return (
          <Input
            placeholder="Document"
            onChange={(e) =>
              onChangeAmendment(e.target.value, record.id, "lc_amendment_file")
            }
            value={text}
          />
        );
      },
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record, index) => {
        return (
          <Space direction="horizontal">
            <Button icon={<PlusOutlined />} onClick={addRow} />
            <Button
              icon={<MinusOutlined />}
              onClick={() => removeRow(record?.id)}
            />
          </Space>
        );
      },
    },
  ];


  const piTableColumns = [
    {
      title: "Number",
      dataIndex: "pi_number",
      key: "pi_number",
    },
    {
      title: "Date",
      dataIndex: "pi_date",
      key: "pi_date",
    },    
    {
      title: "Quantity",
      dataIndex: "items_quantity",
      key: "items_quantity",
    },
    {
      title: "Value",
      dataIndex: "items_value",
      key: "items_value",
    },
    {
      title: "Unit",
      key: "unit",
      dataIndex: "invoice_unit",
      render: (value, record) => {
        return <div>{value ? value : "As per PI"}</div>;
      },
    },
    {
      title: "Documents",
      dataIndex: ["pi_document"],
      key: "documents",
      render: (documents, record) => {
        return (
          documents &&
          documents.length > 0 &&
          documents.map((docItem, docIndex) => {
            const fileName =
              docItem.document.split("storage/import_pi_document/")[1] ||
              docItem.document;
            const limitedName =
              fileName.length > 10
                ? `${fileName.slice(0, 10) + "..."}`
                : fileName;
            return (
              <Button
                type="text"
                onClick={() =>
                  window.open(api_base_url + "/" + docItem.document, "_blank")
                }
                disabled={view}
              >
                {limitedName}
                <FilePdfOutlined />
              </Button>
            );
          })
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => {
        return (
          <Button
            style={{ width: "100%" }}
            type={record?.isCurrent ? "danger" : "primary"}
            onClick={() => {
              if (record?.isCurrent) {
                removePI(record?.id);
              } else {
                addPI(record?.id);
              }
            }}
            size="small"
            disabled={view}
          >
            {record?.isCurrent ? "Remove" : "Add"}
          </Button>
        );
      },
    },
  ];

  const onChangeTolerance = (percentage) => {
    const totalB2BLCValue = form.getFieldValue("total_lc_value");
    const totalB2BLCQuantity = form.getFieldValue("total_lc_quantity");
    const updatedB2BLCValue =
      totalB2BLCValue + (totalB2BLCValue * percentage) / 100;
    const updatedB2BLCQuantity =
      totalB2BLCQuantity + (totalB2BLCQuantity * percentage) / 100;

    // setB2BLcValue(updatedB2BLCValue);
    // setB2BLcQuantity(updatedB2BLCQuantity);
    form.setFieldsValue({
      total_lc_value: updatedB2BLCValue,
      total_lc_quantity: updatedB2BLCQuantity,
      balance_value: updatedB2BLCValue,
      balance_quantity: updatedB2BLCQuantity,
    });
  };

  const paymentTypeChange = (value) => {
    if (value && value !== "LC") {
      setHide(true);
    } else {
      setHide(false)
    }

    switch (value) {
      case "RTGS":
        setWithShowPaymentTerms(false);
        break;
      case "TT":
        setWithShowPaymentTerms(false);
        break;
      default:
        setWithShowPaymentTerms(true);
    }
  }
  //multiselect for FORM


  const getFromsList = async (filterValues) => {
    const query = `${ACCEPTANCE_FROMS}`;
    const bodyData = {
      ...filterValues,
      per_page: filterValues?.per_page || 1000,
    };

    const response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      setFroms(response?.data?.data);
    }
  };

  const onFromChange = (event) => {
    setAddedFrom(event.target.value);
  };
  const addNewFrom = async (event) => {
    event.preventDefault();
    const query = `${ACCEPTANCE_FROM_ADD}`;
    const bodyData = {
      froms: addedFrom,
    };

    const response = await postData(query, bodyData);

    if (response && response?.code === 200) {
      setAddedFrom(null);
      HandleNotification(
        "success",
        "bottomRight",
        "From added successfully",
        null,
      );
      getFromsList();
    }
  };

  console.log('lcdetailsList',lcdetailsList);
  

  return (
    <div className="containt-body pt-3">
      <Form
        layout="vertical"
        name="control-hooks"
        form={form}
        onFinish={onFinishSalesContract}
      >
        <Card>
          <Row gutter={6}>
            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Sales Contract "
                name="sales_contract_id"
                rules={[
                  {
                    required: true,
                    message: "Sales Contract is required",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Sales Contract"
                  style={{ width: "100%" }}
                  onChange={(value) => {
                    getSupplierList(value);
                  }}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  disabled={view}
                  size="small"
                >
                  {salesContractList.map((option) => {
                    return (
                      <option key={option.id} value={option.id}>
                        {option.reference_no}
                      </option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item label="Supplier Name" name="supplier_id">
                <Select
                  showSearch
                  placeholder="Select a Supplier"
                  optionFilterProp="children"
                  onChange={(value) => {
                    handleShowHideInsurence(value);
                    getPiNumbersList(value, isEdit);
                    getSupplierType(value, isEdit);
                  }}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  disabled={view}
                  size="small"
                >
                  {supplier?.length &&
                    supplier.map((buy) => (
                      <Option value={buy?.id} key={buy?.id}>
                        {buy.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>

            <Col className="gutter-row" span={4}>
              <Form.Item label="Supplier Type" name="supplier_type">
                <Input style={{ width: "100%" }} disabled={true} size="small" />
              </Form.Item>
            </Col>

            <Col className="gutter-row" span={0}>
              <Form.Item label="HS Code" name="hs_code" hidden>
                <Input style={{ width: "100%" }} disabled={view} size="small" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item label="Payment Type" name="payment_type">
                <Select
                  placeholder="Payment Type"
                  style={{ width: "100%" }}
                  onChange={(value) => {
                    paymentTypeChange(value);
                  }}
                  disabled={view}
                  size="small"
                >
                  <option value="LC">LC</option>
                  <option value="RTGS">RTGS</option>
                  <option value="TT">TT</option>
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item
                label="LC/RTGS/TT Number"
                name="b2b_lc_number"
                rules={[
                  {
                    required: true,
                    message: "LC Number is required",
                  },
                ]}
              >
                <Input style={{ width: "100%" }} disabled={view} size="small" />
              </Form.Item>
            </Col>

            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Date"
                name="b2b_lc_date"
                rules={[
                  {
                    required: true,
                    message: "B2B LC Date is required",
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  disabled={view}
                  size="small"
                />
              </Form.Item>
            </Col>

            <Col className="gutter-row" span={4}>
              <Form.Item label="Total Value" name="total_lc_value">
                <InputNumber
                  disabled={true}
                  style={{ width: "100%" }}
                  formatter={(value) => commaSeparateNumber(value)}
                  precision={2}
                  size="small"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item label="Total Quantity" name="total_lc_quantity">
                <InputNumber
                  disabled={true}
                  style={{ width: "100%" }}
                  formatter={(value) => commaSeparateNumber(value)}
                  precision={2}
                  size="small"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item label="Balance Value" name="balance_value">
                <InputNumber
                  disabled={true}
                  style={{ width: "100%" }}
                  formatter={(value) => commaSeparateNumber(value)}
                  precision={2}
                  size="small"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item label="Balance Quantity" name="balance_quantity">
                <InputNumber
                  disabled={true}
                  style={{ width: "100%" }}
                  formatter={(value) => commaSeparateNumber(value)}
                  precision={2}
                  size="small"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Description"
                name="description"
                rules={[
                  {
                    required: true,
                    message: "Please input Description!",
                  },
                ]}
              >
                <Select
                  placeholder="Description"
                  style={{ width: "100%" }}
                  onChange={(value) => onSelectDescriptionValue(value)}
                  disabled={view}
                  size="small"
                >
                  {description.map((option) => {
                    return (
                      <option key={option.id} value={option.id}>
                        {option?.name}
                      </option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            {isDescription && (
              <>
                <Col className="gutter-row" span={4}>
                  <Form.Item label="Fabric LC value" name="fabric_lc_value">
                    <InputNumber
                      disabled={view}
                      style={{ width: "100%" }}
                      formatter={(value) => commaSeparateNumber(value)}
                      precision={2}
                      size="small"
                    />
                  </Form.Item>
                </Col>

                <Col className="gutter-row" span={4}>
                  <Form.Item
                    label="Accessories LC Value"
                    name="accessories_lc_value"
                  >
                    <InputNumber
                      disabled={view}
                      style={{ width: "100%" }}
                      formatter={(value) => commaSeparateNumber(value)}
                      precision={2}
                      size="small"
                    />
                  </Form.Item>
                </Col>
              </>
            )}
            {/* {showInsurenceCover && (
              <Col className="gutter-row" span={4}>
                {" "}
                <InsuranceCoverField view={view} />
              </Col>
            )} */}
            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Trade/Freight Term"
                name="freight_terms"
                rules={[
                  {
                    required: true,
                    message: "Trade/Freight Term is required",
                  },
                ]}
              >
                <Select
                  placeholder="Trade/Freight Term"
                  style={{ width: "100%" }}
                  disabled={view}
                  size="small"
                >
                  <option value="EXW (Ex-Works)">EXW (Ex-Works)</option>
                  <option value="FCA (Free Carrier)">FCA (Free Carrier)</option>
                  <option value="FAS (Free Alongside Ship)">
                    FAS (Free Alongside Ship)
                  </option>
                  <option value="FOB (Free on Board)">
                    FOB (Free on Board)
                  </option>
                  <option value="CFR (Cost & Freight)">
                    CFR (Cost & Freight)
                  </option>
                  <option value="CIF (Cost Insurance & Freight)">
                    CIF (Cost Insurance & Freight)
                  </option>
                  <option value="CPT ( Carriage Paid To)">
                    CPT ( Carriage Paid To)
                  </option>
                  <option value="CIP (Carriage Insurance Paid To)">
                    CIP (Carriage Insurance Paid To)
                  </option>
                  <option value="DPU (Delivered at Place Unloaded)">
                    DPU (Delivered at Place Unloaded)
                  </option>
                  <option value="DAT (Delivered At Terminal)">
                    DAT (Delivered At Terminal)
                  </option>
                  <option value="DAP (Delivered at Place)">
                    DAP (Delivered at Place)
                  </option>
                  <option value="DDP (Delivered Duty Paid)">
                    DDP (Delivered Duty Paid)
                  </option>
                </Select>
              </Form.Item>
            </Col>
            {withShowPaymentTerms && (
              <>
                <Col className="gutter-row" span={4}>
                  <Form.Item label="Tolerance" name="tolerance">
                    <InputNumber
                      style={{ width: "100%" }}
                      className="w-100"
                      disabled={view}
                      size="small"
                      onChange={(value) => onChangeTolerance(value)}
                      precision={0}
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={4}>
                  <Form.Item
                    label="Terms of Payment"
                    name="terms_of_payment"
                    rules={[
                      {
                        required: hide ? false : true,
                        message: "Terms of Payment is required",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Payment Terms"
                      style={{ width: "100%" }}
                      onChange={(value) => onSelectPaymentTerm(value)}
                      disabled={hide || view}
                      size="small"
                    >
                      {paymentTerms.map((option) => {
                        return (
                          <option key={option.id} value={option.name}>
                            {option?.name}
                          </option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </>
            )}
            {isDeferred && (
              <>
                <Col className="gutter-row" span={4}>
                  <Form.Item
                    label="Deferred days"
                    name="payment_term_deferred"
                    rules={[
                      {
                        required: hide ? false : true,
                        message: "Please input Deferred days!",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Deferred days"
                      style={{ width: "100%" }}
                      disabled={hide || view}
                      size="small"
                    >
                      {paymentTermsDeferred &&
                        paymentTermsDeferred.map((option) => {
                          return (
                            <option key={option.id} value={option.value}>
                              {option.value}
                            </option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={4}>
                  <Form.Item label="From" name="from_date">
                    {/* <Input
                      style={{ width: "100%", color: "#000 !important" }}
                      disabled={hide}
                    /> */}
                    <Select
                      style={{
                        width: "100%",
                      }}
                      placeholder="From"
                      mode="multiple"
                      disabled={view}
                      size="small"
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          <Divider
                            style={{
                              margin: "8px 0",
                            }}
                          />
                          <Space
                            style={{
                              padding: "0 8px 4px",
                            }}
                          >
                            <Input
                              placeholder="Please enter item"
                              ref={inputRef}
                              value={addedFrom}
                              onChange={onFromChange}
                              onKeyDown={(e) => e.stopPropagation()}
                            />
                            <Button
                              type="text"
                              icon={<PlusOutlined />}
                              onClick={addNewFrom}
                            >
                              Add
                            </Button>
                          </Space>
                        </>
                      )}
                      options={
                        froms &&
                        froms.map((item) => ({
                          label: item?.froms,
                          value: item?.id,
                        }))
                      }
                    />
                  </Form.Item>
                </Col>
              </>
            )}
            {withShowPaymentTerms && (
              <>
                <Col className="gutter-row" span={4}>
                  <Form.Item
                    label="Shipment Date"
                    name="shipment_date"
                    rules={[
                      {
                        required: hide ? false : true,
                        message: "Shipment Date is required",
                      },
                    ]}
                  >
                    <DatePicker
                      style={{ width: "100%" }}
                      disabled={view ?? hide}
                      size="small"
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={4}>
                  <Form.Item
                    label="Expiry Date"
                    name="expiry_date"
                    rules={[
                      {
                        required: hide ? false : true,
                        message: "Expiry Date is required",
                      },
                    ]}
                  >
                    <DatePicker
                      style={{ width: "100%" }}
                      disabled={view ?? hide}
                      size="small"
                    />
                  </Form.Item>
                </Col>
              </>
            )}
          </Row>
        </Card>
        <B2bAmendmentTable
          lcdetailsList={lcdetailsList}
          setLcdetailsList={setLcdetailsList}
          SupplierType={SupplierType}
          view={view}
        />
        <Card title="PI Details">
          <Table dataSource={piNumbersList} columns={piTableColumns} />
        </Card>
      </Form>

      {/* <ImportB2blcDocument
        DOC_ADD={IMPORT_B2BLC_ADD_DOC}
        setFileList={setFileList}
        filesList={filesList}
        view={view}
      /> */}
    </div>
  );
});

export default AddExportSalesContractForm;


// LOCAL COMPOENT
// export const InsuranceCoverField = ({ view }) => {
//   const inputRef = useRef(null);

//   const [insuranceItems, setInsuranceItems] = useState([]);
//   const [addNewInsuranceItem, setAddNewInsuranceItem] = useState("")
//   const addInsuranceItemHandle = (e) => {
//     e.preventDefault();

//     if (addNewInsuranceItem === "" || addNewInsuranceItem === null) {
//       return;
//     }

//     storeTypes({ name: addNewInsuranceItem }) // API STORE

//     setAddNewInsuranceItem("");
//     // setSelectedItem(null);
//     setTimeout(() => {
//       inputRef.current?.focus();
//     }, 0);
//   };

//   const onInsuranceChangeHandle = (event) => {
//     setAddNewInsuranceItem(event.target.value);
//   };
//   // API START
//   const getTypes = useCallback(async () => {
//     let res = await getData(IMPORT_COVER_NOT);
//     if (res) {
//       let masterData = res.data.data;
//       if (masterData) {
//         setInsuranceItems(masterData)
//       }
//     }
//   }, []);
//   const storeTypes = async (bodyData) => {
//     const res = await postData(IMPORT_COVER_NOT_ADD, bodyData);
//     if (res) {
//       getTypes()
//     }
//   };
//   // API END

//   // useEffect START
//   useEffect(() => { getTypes() }, [getTypes])
//   // useEffect END
//   // RENDER CONTENT START
//   const dropdownRenderSelect = (menu) => (
//     <>
//       {menu}
//       <Divider style={{ margin: "8px 0" }} />
//       <Space style={{ padding: "0 8px 4px" }}>
//         <Input
//           placeholder="Please enter item"
//           ref={inputRef}
//           value={addNewInsuranceItem}
//           onChange={onInsuranceChangeHandle}
//           onKeyDown={(e) => e.stopPropagation()}
//           disabled={view}
//           size="small"
//         />
//         <Button type="text" onClick={addInsuranceItemHandle}>
//           Add item
//         </Button>
//       </Space>
//     </>
//   );
//   const optionsInsurance = insuranceItems.map((item) => ({
//     label: item?.name,
//     value: item?.id,
//   }));
//   // RENDER CONTENT END

//   return (
//     <Form.Item label="Insurance Cover Noted No." name="cover_noted_number">
//       <Select
//         mode="multiple"
//         disabled={view}
//         placeholder="Insurance Cover"
//         dropdownRender={(menu) => dropdownRenderSelect(menu)}
//         options={optionsInsurance}
//         size="small"
//       />
//     </Form.Item>
//   )
// }