// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .titleHeader{} */
/* .totalContainer{} */

.production_totalItem__T4HLg {
    display: inline-block;
    padding: 4px;
    margin-right: 8px;
    background-color: "#f9f9f9";
  }

  .production_totalLabel__fOURX {
    font-weight: bold; 
    color: #387b9f;
  }

`, "",{"version":3,"sources":["webpack://./src/components/Production/production.module.css"],"names":[],"mappings":"AAAA,mBAAmB;AACnB,sBAAsB;;AAEtB;IACI,qBAAqB;IACrB,YAAY;IACZ,iBAAiB;IACjB,2BAA2B;EAC7B;;EAEA;IACE,iBAAiB;IACjB,cAAc;EAChB","sourcesContent":["/* .titleHeader{} */\n/* .totalContainer{} */\n\n.totalItem {\n    display: inline-block;\n    padding: 4px;\n    margin-right: 8px;\n    background-color: \"#f9f9f9\";\n  }\n\n  .totalLabel {\n    font-weight: bold; \n    color: #387b9f;\n  }\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"totalItem": `production_totalItem__T4HLg`,
	"totalLabel": `production_totalLabel__fOURX`
};
export default ___CSS_LOADER_EXPORT___;
