import { Col, Form, Row, Select, Input, DatePicker } from "antd";
import { useEffect, useState } from "react";
import {
  GET_BUYER_ENDPOINT,
  GET_BUYING_OFFICE_ENDPOINT,
  SALES_CONTRACT_LIST,
  STYLE_MERCHANDISING_LIST,
  GET_SUPPLIER_ENDPOINT,
  ALL_SALES_CONTRACT_LIST
} from "../../../apiServices/API_ENDPOINTS";
import { getData } from "../../../apiServices/common";
const ImportDetailFilterFields = ({ initialTableData, setSupplierType}) => {
  const { Option } = Select;
  const [salseCotractList, setSalseCotractList] = useState([]);
  const [supplierList, setSupplierList] = useState([]);

  const supplierTypes = [
    { id: 0, value: "Foreign" },
    { id: 1, value: "Local" },
    { id: 2, value: "EPZ" },
  ];

  useEffect(() => {
    getSalseCotractList();
    getSupplierList();
  }, []);

  const getSalseCotractList = async () => {
    let res = await getData(ALL_SALES_CONTRACT_LIST);

    if (res) {
      setSalseCotractList(res?.data?.data || []);
    }
  };

  const getSupplierList = async () => {
    let res = await getData(GET_SUPPLIER_ENDPOINT);

    if (res) {
      setSupplierList(res?.data?.data?.data || []);
    }
  };
  return (
    <>

      {/* <Col span={3}>
        <Form.Item label="Supplier Type" name="supplier_type">
          <Select 
            placeholder="Select Type" 
            size="small"
            onSelect={(value) => setSupplierType(value)}
          >
            {supplierTypes.map((item) => (
              <Option key={item?.id} value={item?.id}>
                {item?.value}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col> */}
      
      <Col span={3}>
        <Form.Item label="Invoice Number" name="invoice_number">
          <Input placeholder="Write Invoice Number" size="small" />
        </Form.Item>
      </Col>
      <Col span={3}>
        <Form.Item label="LC Number" name="b2b_lc_number">
          <Input placeholder="Write LC Number" size="small" />
        </Form.Item>
      </Col>
      <Col span={2}>
        <Form.Item label="Import Quantity" name="quantity_ctn">
          <Input placeholder="Write Import Quantity" size="small" />
        </Form.Item>
      </Col>
      <Col span={2}>
        <Form.Item label="Invoice Quantity" name="quantity_yds">
          <Input placeholder="Write Invoice Quantity" size="small" />
        </Form.Item>
      </Col>
      <Col span={2}>
        <Form.Item label="Invoice Value" name="invoice_value">
          <Input placeholder="Write Invoice Value" size="small" />
        </Form.Item>
      </Col>
      <Col span={2}>
        <Form.Item label="From Date" name="from_date">
          <DatePicker placeholder="From Date" size="small" />
        </Form.Item>
      </Col>
      <Col span={2}>
        <Form.Item label="To Date" name="to_date">
          <DatePicker placeholder="To Date" size="small" />
        </Form.Item>
      </Col>
      
      
    </>
  );
};

export default ImportDetailFilterFields;
