import { useCallback, useEffect, useState } from "react";
import { DeleteTwoTone, EditTwoTone, PlusOutlined } from "@ant-design/icons";
import {
    Button,
    message,
    Table,
    Row,
    Col,
    Typography,
    Dropdown,
    Menu,
    Popconfirm,
    Modal,
    Form,
    Input,
    Select,
    Switch,
    DatePicker,
    TimePicker,
    Divider,
    Space,
} from "antd";
import AppContent from "../../common/AppContent";
import AppPageHeader from "../../common/PageHeader";
import ExtraContent from "../Configuration/CommonComponents/ExtraContent";
import FilterComponent from "../Configuration/CommonComponents/FilterComponent";
import { getData, postData, deleteData, putData } from "../../apiServices/common";
import {
    BUYER_VISIT_DATE_LIST_ENDPOINT,
    BUYER_VISIT_DATE_ADD_ENDPOINT,
    BUYER_VISIT_DATE_EDIT_ENDPOINT,
    BUYER_VISIT_DATE_DELETE_ENDPOINT,
    PURPOSE_LIST_ENDPOINT,
    PURPOSE_ADD_ENDPOINT,
} from "../../apiServices/API_ENDPOINTS";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import moment from "moment";

const BuyerVisitDate = () => {

  const onDateChange = (date, dateString) => {
    console.log(date, dateString);
  };
  
  const onTimeChange = (time, timeString) => {
    console.log(time, timeString); // You can handle the selected time here
  };
  
  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;

  // States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [metaData, setMetaData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [ purposeList, setPurposeList ] = useState();
  // const [items, setItems] = useState(purposeList || []);
  const [newPurpose, setNewPurpose] = useState("");


  // Antd
  const [visit_dateForm] = Form.useForm();
  const { Option } = Select;
  const { Title } = Typography;

  // // Redux
  // const filterData = useSelector(({ filter }) => filter.filterData);

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};

    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  const getAllBuyerVisitDate = useCallback(
    async (filterValues) => {
      const payload = {
        page: filterValues?.page || currentPage,
        ...filterValues,
      }

      setLoading(true);

      const query = `${BUYER_VISIT_DATE_LIST_ENDPOINT}`;
      const bodyData = {
        ...payload,
      };

      const response = await getData(query, false, bodyData);
      if (response?.status === 200) {
        setLoading(false);
        setMetaData({
          defaultPageSize: response?.data?.data?.per_page,
          current: response?.data?.data?.current_page,
          defaultCurrent: response?.data?.data?.current_page,
          total: response?.data?.data?.total,
        });
        setTableData(response?.data?.data?.data);
      } else {
        setLoading(false);
        message.error("Error getting Buyer Visit Date data");
      }
    },
    [currentPage],
  );

  const getAllVisitPurposes = async (filterValues) => {
    setLoading(true);

    const query = `${PURPOSE_LIST_ENDPOINT}`;
    const payload = {
      ...filterValues,
    }

    const response = await getData(query, false, payload);
    if (response?.status === 200) {
      setLoading(false);
      const filteredpurposeListData = response?.data?.data?.data?.filter(item => item.type === "Buyer Visit");
      console.log("mamuuu", filteredpurposeListData);
      setPurposeList(filteredpurposeListData);
    } else {
      setLoading(false);
      message.error("Error getting warehouse data");
    }
  };

  console.log("Purpose List gulo dekhi", purposeList);

  const addPurpose = async () => {
    if (newPurpose) {
      setLoading(true);
      const response = await postData(PURPOSE_ADD_ENDPOINT, { name: newPurpose, type: "Buyer Visit" });
      // console.log("response_444", response);
      if (response?.code === 200) {
        message.success("Purpose added successfully!");
        
        // Define the new purpose item and add it to the list
        const newPurposeItem = { id: response?.data?.id, name: newPurpose };
        setPurposeList([...purposeList, newPurposeItem]);
        
        setNewPurpose(""); // Reset input field

        // Set focus on the newly added purpose after updating the state
        setTimeout(() => {
          visit_dateForm.setFieldsValue({
            visit_purpose: newPurposeItem.name,
          });
        }, 0);

      } else {
        message.error("Failed to add purpose");
      }
      setLoading(false);
    }
  };

  

  // console.log("Line List dekhi", salesContractList);

  // Effects
  useEffect(() => {
    getAllBuyerVisitDate();
    getAllVisitPurposes();
  }, [refresh, getAllBuyerVisitDate]);

  const onModalOpen = () => {
    setIsModalOpen(true);
  };

  const onCloseModal = () => {
    visit_dateForm.resetFields();
    setIsModalOpen(false);
    setIsEdit(false);
  };

  const refetch = () => {
    setRefresh(refresh + 1);
  };

  const deleteSelectedvisit_date = async (id) => {
    setLoading(true);
    const response = await deleteData(`${BUYER_VISIT_DATE_DELETE_ENDPOINT}/${id}`);

    if (response && response?.code === 200) {
      setLoading(false);
      message.success("Buyer Visit Date Deleted Successfully");
      refetch();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const editSelectedvisit_date = async (formValues) => {
    setLoading(true);

    const bodyData = {
      buyer: formValues?.buyer,
      person: formValues?.person,
      contact: formValues?.contact,
      visit_date: moment(formValues?.visit_date).format('YYYY-MM-DD'),
      visit_purpose: formValues?.visit_purpose ? formValues?.visit_purpose : null,
    };

    const response = await putData(
      `${BUYER_VISIT_DATE_EDIT_ENDPOINT}/${formValues?.id}`,
      bodyData,
    );

    if (response && response?.code === 200) {
      setLoading(false);
      message.success("Buyer Visit Date Edited Successfully");
      refetch();
      visit_dateForm.resetFields();
      onCloseModal();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const createNewvisit_date = async (formValues) => {
    setLoading(true);

    const bodyData = {
      buyer: formValues?.buyer,
      person: formValues?.person,
      contact: formValues?.contact,
      visit_date: moment(formValues?.visit_date).format('YYYY-MM-DD'),
      visit_purpose: formValues?.visit_purpose ? formValues?.visit_purpose : null,
    };

    const response = await postData(BUYER_VISIT_DATE_ADD_ENDPOINT, bodyData);

    if (response && response?.code === 200) {
      setLoading(false);
      message.success("Buyer Visit Date Created Successfully");
      refetch();
      visit_dateForm.resetFields();
      onCloseModal();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const openModalForEdit = (record) => {
    setIsEdit(true);

    console.log("recordsss", record);

    visit_dateForm.setFieldsValue({
      id: record?.id,
      buyer: record?.buyer,
      person: record?.person,
      contact: record?.contact,
      visit_date: moment(record?.visit_date, 'YYYY-MM-DD'), // Use moment to set the date
      visit_purpose: record?.visit_purpose ? record?.visit_purpose : null,

    });
    // Trigger handleSelectChange to populate buyer and sales contract
    setIsModalOpen(true);
  };

  const changePage = (newPage) => {
    setCurrentPage(newPage);
    refetch();
  };

  const tableColumns = [
    {
      title: "Potential Buying Office / Buyer",
      dataIndex: ["buyer"],
      key: "buyer",
    },
    {
      title: "Person",
      dataIndex: ["person"],
      key: "person",
    },
    {
      title: "Contact",
      dataIndex: ["contact"],
      key: "contact",
    },
    {
      title: "Visit Date",
      dataIndex: ["visit_date"],
      key: "visit_date",
    },
    {
      title: "Visit Purpose",
      dataIndex: ["visit_purpose"],
      key: "visit_purpose",
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (_, record) => {
        return (
          <Dropdown.Button
            overlay={
              <Menu
                items={[
                  {
                    label: (
                      <Popconfirm
                        title="Are you sure you want to delete?"
                        onConfirm={() => {
                          deleteSelectedvisit_date(record?.id);
                        }}
                      >
                        <span>
                          <DeleteTwoTone />
                        </span>
                        <span
                          style={{
                            color: "red",
                            marginLeft: 5,
                          }}
                        >
                          Delete
                        </span>
                      </Popconfirm>
                    ),
                    key: "1",
                    
                  },
                  {
                    label: (
                      <>
                        <span>
                          <EditTwoTone />
                        </span>
                        <span
                          type="button"
                          style={{ marginLeft: 5 }}
                          onClick={() => {
                            openModalForEdit(record);
                          }}
                        >
                          Edit
                        </span>
                      </>
                    ),
                    key: "2",
                    
                  },
                ]}
              />
            }
          ></Dropdown.Button>
        );
      },
    },
  ];

  return (
    <>
      <AppContent
        header={
          <AppPageHeader
            extra={
              <ExtraContent
                refresh={refresh}
                setRefresh={setRefresh}
                handleAddItemOpen={onModalOpen}
                
                addButtonText="Add New"
                showFilter={showFilter}
                setShowFilter={setShowFilter}
              />
            }
            title={<Title level={5}>Buyer Visit Date</Title>}
          />
        }
        content={
          <>
            <FilterComponent
              tableData={tableData}
              filterAPI={getAllBuyerVisitDate}
              showFilter={showFilter}
              setShowFilter={setShowFilter}
              filterName="visit_date"
            />

            <div className="table" style={{ marginTop: 10 }}>
              <Table
                loading={loading}
                dataSource={tableData}
                columns={tableColumns}
                pagination={{
                  showSizeChanger: false,
                  defaultPageSize: metaData?.defaultPageSize,
                  current: metaData?.current,
                  onChange: (newPage) => {
                    changePage(newPage);
                  },
                  defaultCurrent: metaData?.defaultCurrent,
                  total: metaData?.total,
                }}
              />
            </div>
          </>
        }
      />
      <Modal
        open={isModalOpen}
        title={isEdit ? "Edit Buyer Visit Date" : "Create Buyer Visit Date"}
        onCancel={() => {
          onCloseModal();
        }}
        maskStyle={{ backdropFilter: "blur(4px)" }}
        footer={null}
      >
        <Form
          form={visit_dateForm}
          layout="vertical"
          onFinish={isEdit ? editSelectedvisit_date : createNewvisit_date}
        >
          <Row gutter={6}>
            <Col span={24}>
                <Form.Item name="id" hidden />
                <Form.Item
                  name="buyer"
                placeholder="Buyer"
                  label="Buyer"
                >
                  <Input />
                </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="person"
                placeholder="Person"
                label="Person"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="contact"
                placeholder="Contact"
                label="Contact"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="visit_date"
                placeholder="Buyer Visit Date"
                label="Buyer Visit Date"
                rules={[
                  {
                    required: true,
                    message: "Buyer Visit Date is Required",
                  },
                ]}
              >
                <DatePicker style={{width: "100%"}} onChange={onDateChange} />
              </Form.Item>
            </Col>
            
            <Col span={24}>
            <Form.Item
              name="visit_purpose"
              label="Select a Visit Purpose"
              rules={[
                {
                  required: true,
                  message: "Visit Purpose is Required",
                },
              ]}
            >
              <Select
                placeholder="Select a Visit Purpose"
                showSearch
                style={{ width: "100%" }}
                onSearch={(value) => getAllVisitPurposes({ name: value })}
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider style={{ margin: "8px 0" }} />
                    <Space style={{ padding: "0 8px 4px" }}>
                      <Input
                        style={{ width: "100%" }}
                        placeholder="Enter New Purpose"
                        value={newPurpose}
                        onChange={(e) => setNewPurpose(e.target.value)}
                      />
                      <Button
                        type="text"
                        icon={<PlusOutlined />}
                        onClick={addPurpose}
                      >
                        Add Purpose
                      </Button>
                    </Space>
                  </>
                )}
              >
                {purposeList?.map((item) => (
                  <Option key={item.id} value={item.name}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            </Col>
          </Row>
          <Row gutter={6}>
            <Col span={12}>
              <Button
                type="danger"
                style={{ width: "100%" }}
                onClick={() => {
                  onCloseModal();
                }}
              >
                Discard
              </Button>
            </Col>
            <Col span={12}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
                loading={loading}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default BuyerVisitDate;
