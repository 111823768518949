// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-page-header {
  background-color: #fff !important;
  padding: 5px !important;
  border-bottom: 1px solid #F0F2F5;
}
.app-page-header .ant-page-header-heading-title {
  font-size: 16px !important;
}
.app-page-header .ant-page-header-heading-left {
  margin: unset;
}

.app-page-header.buttonRight .ant-page-header-content {
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
  padding: 0;
}

.extra-button-container {
  display: flex;
}`, "",{"version":3,"sources":["webpack://./src/common/PageHeader/index.scss"],"names":[],"mappings":"AAAA;EACI,iCAAA;EACA,uBAAA;EACA,gCAAA;AACJ;AACI;EACI,0BAAA;AACR;AAEI;EACI,aAAA;AAAR;;AAIA;EACI,kBAAA;EACA,WAAA;EACA,QAAA;EACA,2BAAA;EACA,UAAA;AADJ;;AAIA;EACI,aAAA;AADJ","sourcesContent":[".app-page-header {\n    background-color: #fff !important;\n    padding: 5px !important;\n    border-bottom: 1px solid #F0F2F5;\n\n    .ant-page-header-heading-title {\n        font-size: 16px !important;\n    }\n\n    .ant-page-header-heading-left {\n        margin: unset;\n    }\n}\n\n.app-page-header.buttonRight .ant-page-header-content {\n    position: absolute;\n    right: 25px;\n    top: 50%;\n    transform: translateY(-50%);\n    padding: 0;\n}\n\n.extra-button-container {\n    display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
