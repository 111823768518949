// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.site-layout .site-layout-background {
    background: #fff;
    padding: 2px 24px 24px ;
    margin-right: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/common/AppLayOut/index.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,uBAAuB;IACvB,iBAAiB;AACrB","sourcesContent":[".site-layout .site-layout-background {\n    background: #fff;\n    padding: 2px 24px 24px ;\n    margin-right: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
