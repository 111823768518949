// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-table-thead .ant-table-cell {
    background-color: #1b4b72;
    color: white;
    font-size: 12px;
    font-weight: bold;
}
.ant-table .editable-row {
    background-color: #ffffff;
}

/*.ant-form-vertical .ant-form-item-label {*/
/*    font-weight: 600;*/
/*}*/`, "",{"version":3,"sources":["webpack://./src/components/Production/CuttingItem/index.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,YAAY;IACZ,eAAe;IACf,iBAAiB;AACrB;AACA;IACI,yBAAyB;AAC7B;;AAEA,4CAA4C;AAC5C,wBAAwB;AACxB,IAAI","sourcesContent":[".ant-table-thead .ant-table-cell {\n    background-color: #1b4b72;\n    color: white;\n    font-size: 12px;\n    font-weight: bold;\n}\n.ant-table .editable-row {\n    background-color: #ffffff;\n}\n\n/*.ant-form-vertical .ant-form-item-label {*/\n/*    font-weight: 600;*/\n/*}*/"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
