import {
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Switch,
} from "antd";
import moment from "moment";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import {
  CREATE_BOM_ENDPOINT,
  PO_WISE_COLOR,
  PREVIOUS_STYLE_LIST,
  STYLE_MERCHANDISING_LIST,
  UPDATE_BOM,
} from "../../../apiServices/API_ENDPOINTS";
import { getData, postData } from "../../../apiServices/common";
import { alertPop } from "../../../apiServices/common/helper";
import {
  commaSeparateNumber,
  isArrayAndHasValue,
} from "../../../utils/functions";
import ItemDetails from "./ItemDetails";

const AddOrUpdateForm = forwardRef((props, ref) => {
  // Props
  const { permitInfo, view } = props;

  // States
  const [buyingOffice, setBuyingOffice] = useState([]);
  const [buyerID, setBuyerID] = useState([]);
  const [creaetionEntryDate, setCreaetionEntryDate] = useState("");
  const [contractIds, setContractIds] = useState([]);
  const [selseContractStyleDetailList, setSelseContractStyleDetailList] =
    useState([]);
  const [stateUpdated, setStateUpdated] = useState(false);
  const [prevStyleId, setPrevStyleId] = useState(null);
  const [itemDetailsList, setItemDetailsList] = useState([]);
  const [colorList, setColorList] = useState([]);
  const [salesContractId, setSalesContractId] = useState();
  const [styleNoID, setStyleNoID] = useState();
  const [subStyleList, setSubStyleList] = useState();
  const [previousStylesList, setPreviousStylesList] = useState([]);
  const [style, setStyle] = useState();
  const [isFactory, setIsFactory] = useState(
    permitInfo?.sub_contract_factory || false,
  );

  // Router
  const { bomId } = useParams();

  // Antd
  const { Option } = Select;
  const [form] = Form.useForm();
  const brand_id_watch = Form.useWatch("brand_id", form);

  const getPreviousStyleList = async () => {
    let res = await getData(PREVIOUS_STYLE_LIST);

    if (res) {
      setPreviousStylesList(res?.data?.data);
    }
  };

  useEffect(() => {
    if (
      selseContractStyleDetailList &&
      selseContractStyleDetailList.length > 0
    ) {
      setStateUpdated(true);
    }
  }, [selseContractStyleDetailList]);

  const postDataMakerFunction = (itemDetailsList, dataPost, isEdit) => {
    const updatedData = {
      buying_office_id: dataPost?.buying_office_id,
      sub_contract_factory_id: dataPost?.sub_contract_factory_id,
      sales_contract_id: dataPost?.sales_contract_id,
      buyer_id: dataPost?.buyer_id,
      style_id: dataPost?.style_id,
      brand_id: dataPost?.brand_id,
      subcontract_status: dataPost?.subcontract_status,
      entry_date: dataPost?.entry_date,
      bill_of_material_details: itemDetailsList.map((item) => {
        return {
          id: !item?.is_add ? item?.id : null,
          bom_id: item?.bom_id,
          item_id: item?.item_id,
          item_category_id: item?.item_category_id,
          unit_id: item?.unit_id || item?.unit_info?.id,
          color_id: item?.color_id || item?.color_info?.id,
          order_quantity: item?.order_quantity
            ? Number(item?.order_quantity)
            : 0,
          per_product_required_quantity: item?.per_product_required_quantity
            ? Number(item?.per_product_required_quantity)
            : 0,
          extra_qty: item?.extra_qty ? Number(item?.extra_qty) : 0,
          required_quantity: item?.required_quantity
            ? Number(item?.required_quantity)
            : 0,
          unit_cost: item?.unit_cost ? Number(item?.unit_cost) : 0,
          total_cost: item?.total_cost ? Number(item?.total_cost) : 0,
          sub_style_id:
            item?.sub_style_id || item?.color_info?.sub_style_id || null,
          suppliers: item?.suppliers?.map((supplierItem) => {
            return {
              id: isEdit ? supplierItem?.id : 0,
              supplier_id: supplierItem?.supplier_id,
              is_delete: isEdit ? supplierItem?.is_delete : 0,
            };
          }),
          deleted_suppliers: item?.deleted_suppliers || [],
        };
      }),
    };

    return updatedData;
  };

  useImperativeHandle(ref, () => ({
    submit() {
      form
        .validateFields()
        .then(async (values) => {
          let dataPost = {
            buying_office_id: values.buying_office_id || null,
            sales_contract_id: values.sales_contract_id || null,
            buyer_id: values?.buyer_id || null,
            style_id: values.style_no || null,
            brand_id: values?.brand_id || null,
            sub_contract_factory_id: values?.sub_contract_factory_id || null,
            subcontract_status: values?.subcontract_status ? 1 : 0,
            entry_date: values.creation_date || null,
            sub_style_id: [],
            item_category_id: [],
            item_id: [],
            unit_id: [],
            color_id: [],
            supplier_ids: [],
            order_quantity: [],
            per_product_required_quantity: [],
            required_quantity: [],
            unit_cost: [],
            total_cost: [],
            bom_detail_id: [],
            extra_qty: [],
          };

          if (dataPost.entry_date === "" || dataPost.entry_date === undefined) {
            alertPop("error", "Please Insert BOM Creation Date");
            return;
          }

          if (permitInfo?.id) {
            values.id = permitInfo?.id;
            let payload = null;
            payload = postDataMakerFunction(itemDetailsList, dataPost, true);

            let res = await postData(UPDATE_BOM + permitInfo?.id, payload);
            if (res) {
              alertPop("success", "BOM Updated Successfully");
            }
          } else {
            let payload = null;
            if (itemDetailsList.length > 0) {
              payload = postDataMakerFunction(itemDetailsList, dataPost);
            }

            let res = await postData(CREATE_BOM_ENDPOINT, payload);
            if (res) {
              alertPop("success", "BOM Created Successfully");
            }
          }
        })
        .catch((errorInfo) => {
          alertPop("error", "Error Occured");
        });
    },
    discart() {
      form.resetFields();
    },
  }));

  const getContractIds = async (value, fromOnChange, filterValues) => {
    const query = `/api/buying_office/${value}/edit/api`;
    const bodyData = {
      per_page: 10,
      name: filterValues?.name || "",
    };
    const response = await getData(query, false, bodyData);

    if (fromOnChange) {
      form.resetFields([
        "sales_contract_id",
        "buyer",
        "style_no",
        "brand_name",
        "order_quantity_pc",
      ]);
    }

    if (response && response?.data?.code === 200) {
      setContractIds(response?.data?.data || []);
    }
  };

  const getContractIdDetails = async (value, fromOnChange, filterValues) => {
    const query = `${STYLE_MERCHANDISING_LIST}`;
    const bodyData = {
      ...filterValues,
      per_page: 10,
    };
    const response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      if (fromOnChange) {
        form.resetFields([
          "buyer",
          "style_no",
          "brand_id",
          "brand_name",
          "order_quantity_pc",
        ]);
      }

      setSelseContractStyleDetailList(response.data.data.data);

      setBuyerID(response?.data?.data?.sales_contract?.buyer_info?.id);
      form.setFieldsValue({
        buyer: response?.data?.data?.sales_contract?.buyer_info?.name,
        brand_id: response?.data?.data?.sales_contract?.brand_info?.id,
      });
      return true;
    } else {
      return false;
    }
  };

  const getOrderQuantity = useCallback(
    (value) => {
      const data = selseContractStyleDetailList.find(
        (item) => item.style_id === value,
      );
      setSubStyleList(data?.style_info?.sub_style);
      if (data?.style_id) {
        form.setFieldsValue({
          order_quantity_pc: data.order_quantity,
        });
      }
    },
    [form, selseContractStyleDetailList],
  );

  const getColorList = useCallback(async () => {
    if (styleNoID && salesContractId) {
      let payload = {
        sales_contract_id: salesContractId,
        style_id: styleNoID,
      };
      let res = await postData(PO_WISE_COLOR, payload);

      if (res) {
        setColorList(res?.data);
      }
    }
  }, [salesContractId, styleNoID]);

  const getStyleList = useCallback(async (filterValues) => {
    try {
      const query = `${STYLE_MERCHANDISING_LIST}`;
      const bodyData = {
        per_page: 10,
        name: filterValues?.name || "",
      };
      const response = await getData(query, false, bodyData);

      if (response && response?.data?.code === 200) {
        setSelseContractStyleDetailList(response?.data?.data?.data || []);
      }
    } catch (error) {
      console.log("error", error);
    }
  }, []);

  const getStyleById = useCallback(async (id) => {
    try {
      const query = `/api/styles/${id}/edit`;
      const response = await getData(query);

      if (response && response?.data?.code === 200) {
        const style = response.data?.data?.[0]?.style;
        setIsFactory(style?.factory_info || false);
        setStyle(style);
        setSubStyleList(style?.sub_style);

        form.setFieldsValue({
          buying_office_id: style?.buying_office_info?.id || null,
          buying_office_name: style?.buying_office_info?.name || null,
          buyer_id: style?.buyer_info?.id || null,
          buyer_name: style?.buyer_info?.name || null,
          brand_id: style?.brand_info?.id || null,
          brand_name: style?.brand_info?.name || null,
          factory_name: style?.factory_info?.name || null,
          sub_contract_factory_id: style?.factory_info?.id || null,
          order_quantity_pc:
            style?.active_sales_contract_map_info?.order_quantity || 0,
          sales_contract_id:
            style?.active_sales_contract_map_info?.sales_contract_id || null,
          sales_contract_name:
            style?.active_sales_contract_map_info?.active_sales_contract_info
              ?.reference_no || null,
          subcontract_status: style?.active_sales_contract_map_info
            ?.active_sales_contract_info?.subcontract_status
            ? 1
            : 0,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  }, []);

  useEffect(() => {
    getStyleList();
    getPreviousStyleList();
  }, []);

  useEffect(() => {
    getColorList();
  }, [styleNoID, salesContractId, getColorList]);

  useEffect(() => {
    if (permitInfo) {
      setIsFactory(permitInfo?.sub_contract_factory || false);
      // if (
      //   !buyingOffice?.some((item) => item.id === permitInfo?.buying_office_id)
      // ) {
      //   setBuyingOffice((oldArray) => [
      //     ...oldArray,
      //     {
      //       id: permitInfo?.buying_office_id,
      //       name: permitInfo?.buying_office_info?.name,
      //     },
      //   ]);
      // }
      // if (
      //   !contractIds?.sales_contract?.some(
      //     (item) => item.id === permitInfo?.sales_contract_id,
      //   )
      // ) {
      //   const clone = { ...contractIds };
      //   clone.sales_contract?.push({
      //     id: permitInfo?.sales_contract_id,
      //     reference_no: permitInfo?.sales_contract_info?.reference_no,
      //   });
      //   setContractIds(clone);
      // }

      if (
        !selseContractStyleDetailList?.some(
          (item) => item?.style_info?.id === permitInfo?.style_id,
        )
      ) {
        const clone = [...selseContractStyleDetailList];
        clone.push({
          id: permitInfo?.style_id,
          style_no: permitInfo?.style_info?.style_no,
          brand_info: permitInfo?.style_info?.brand_info,
          style_info: {
            id: permitInfo?.style_id,
            style_no: permitInfo?.style_info?.style_no,
            brand_info: permitInfo?.style_info?.brand_info,
          },
          order_quantity: permitInfo?.style_info?.order_quantity,
        });
        setSelseContractStyleDetailList(clone);
      }
      // if (permitInfo?.buying_office_id) {
      //   getContractIds(permitInfo?.buying_office_id);
      // }

      // getContractIdDetails(permitInfo?.sales_contract_id);
      setStyle(permitInfo);

      form.setFieldsValue({
        buying_office_id: permitInfo?.buying_office_id,
        buying_office_name: permitInfo?.buying_office_info?.name || null,
        buyer_id: permitInfo?.buyer_info?.id,
        buyer_name: permitInfo?.buyer_info?.name || null,
        brand_id: permitInfo?.brand_info?.id,
        brand_name: permitInfo?.brand_info?.name || null,
        sales_contract_id: permitInfo?.sales_contract_info?.id,
        sales_contract_name: permitInfo?.sales_contract_info?.reference_no,
        factory_name: permitInfo?.sub_contract_factory?.name || null,
        sub_contract_factory_id: permitInfo?.sub_contract_factory?.id || null,

        style_no: permitInfo?.style_id,
        creation_date: moment(permitInfo?.entry_date),
        order_quantity_pc:
          permitInfo?.sales_contract_info?.style_details?.find(
            (itm) => itm?.style_id === permitInfo?.style_info?.id,
          )?.order_quantity || null,
        subcontract_status: permitInfo?.subcontract_status ? 1 : 0,
      });
      setStyleNoID(permitInfo?.style_id);
      // setSalesContractId(permitInfo?.sales_contract_id);
      setCreaetionEntryDate(permitInfo?.entry_date);
      setItemDetailsList([]);
      setItemDetailsList([...permitInfo?.bill_of_material_details]);
    }
  }, [permitInfo, setSelseContractStyleDetailList]);

  useEffect(() => {
    if (stateUpdated) {
      getOrderQuantity(permitInfo?.style_id);
    }
  }, [stateUpdated]);

  useEffect(() => {
    if (permitInfo && (permitInfo?.style_id || permitInfo?.style_id === 0)) {
      getOrderQuantity(permitInfo?.style_id);
    }
  }, [permitInfo, getOrderQuantity]);

  const assignBrand = useCallback(
    (value) => {
      const matchedStyle = selseContractStyleDetailList.find(
        (styleItem) => styleItem?.style_info?.id === value,
      );
      form.setFieldsValue({
        brand_name: matchedStyle?.style_info?.brand_info?.name,
        brand_id: matchedStyle?.style_info?.brand_info?.id,
      });
    },
    [form, selseContractStyleDetailList],
  );

  useEffect(() => {
    // On update mode, assign brand name when style id is available
    if (permitInfo) {
      const selectedStyleID = permitInfo?.style_id;
      // assignBrand(selectedStyleID);
    }
  }, [assignBrand, itemDetailsList, permitInfo]);

  const prevStyleSetter = (data) => {
    getContractIds(data?.buying_office_id);
    getContractIdDetails(data?.sales_contract_id);
    // form.setFieldsValue({
    //   buying_office_id: data?.buying_office_id,
    //   sales_contract_id: data?.sales_contract_id,
    //   buyer: data?.buyer_info?.name,
    //   style_no: data?.style_id,
    //   creation_date: moment(data?.entry_date),
    //   brand_name: data?.brand_info?.name,
    // });
    setStyleNoID(data?.style_id);
    setSalesContractId(data?.sales_contract_id);
    setCreaetionEntryDate(data?.entry_date);
    setPrevStyleId(data?.style_id);
  };

  useEffect(() => {
    if (isArrayAndHasValue(selseContractStyleDetailList)) {
      getOrderQuantity(prevStyleId);
    }
  }, [selseContractStyleDetailList, prevStyleId]);

  const callPreviousHistoryBom = async (val) => {
    let url = `/api/bill_of_material/${val}/previous`;
    let res = await getData(url);
    if (res) {
      let masterData = res?.data?.data;
      const dataList = [];
      masterData?.bill_of_material_details?.forEach((item) => {
        let data = {
          ...item,
          is_add: true,
          id: null,
        };

        dataList.push(data);
      });
      setItemDetailsList((oldArray) => [...dataList]);
      prevStyleSetter(masterData);
    }
  };

  return (
    <div className="containt-body">
      <Form layout="vertical" form={form} name="control-hooks">
        <Card>
          <Row gutter={6}>
            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Style No"
                name="style_no"
                rules={[
                  {
                    required: true,
                    message: "Please Select Style No Contract No!",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select a Style No."
                  onChange={(value) => {
                    if (value) {
                      getStyleById(value);
                    }

                    getOrderQuantity(value);
                    setStyleNoID(value);
                    // assignBrand(value);
                  }}
                  optionFilterProp="children"
                  filterOption={false}
                  disabled={view}
                  size="small"
                  onSearch={(value) =>
                    getContractIdDetails(salesContractId, false, {
                      style_no: value,
                    })
                  }
                  allowClear
                  onClear={() => getContractIdDetails(salesContractId, true)}
                >
                  {selseContractStyleDetailList?.length &&
                    selseContractStyleDetailList?.map((style) => (
                      <Option value={style?.id} key={style.id}>
                        {style?.style_no}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item name="deleted_suppliers" hidden />
              <Form.Item
                name="subcontract_status"
                hidden
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </Col>

            {isFactory ? (
              <>
                <Col className="gutter-row" span={4}>
                  <Form.Item name="sub_contract_factory_id" hidden />
                  <Form.Item
                    label="Factory Name"
                    name="factory_name"
                    disabled={view}
                  >
                    <Input className="w-100" disabled={true} size="small" />
                  </Form.Item>
                </Col>
              </>
            ) : (
              <>
                <Col className="gutter-row" span={4}>
                  <Form.Item name="buying_office_id" hidden />
                  <Form.Item label="Buying Office" name="buying_office_name">
                    <Input className="w-100" disabled={true} size="small" />
                  </Form.Item>
                </Col>

                <Col className="gutter-row" span={4}>
                  <Form.Item name="buyer_id" hidden />
                  <Form.Item label="Buyer" name="buyer_name" disabled={view}>
                    <Input className="w-100" disabled={true} size="small" />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={4}>
                  <Form.Item name="brand_id" hidden />
                  <Form.Item label="Brand" name="brand_name" disabled={view}>
                    <Input className="w-100" disabled={true} size="small" />
                  </Form.Item>
                </Col>
              </>
            )}

            <Col className="gutter-row" span={4}>
              <Form.Item name="sales_contract_id" hidden />
              <Form.Item
                label={`${isFactory ? "Sub Contract" : "Sales Contract"} `}
                name="sales_contract_name"
                disabled={view}
              >
                <Input className="w-100" disabled={true} size="small" />
              </Form.Item>
            </Col>

            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Order Qty (pc)"
                name="order_quantity_pc"
                rules={[
                  {
                    required: false,
                    message: "Please input Order Quantity (pc.)",
                  },
                ]}
                disabled={view}
              >
                <InputNumber
                  className="w-100"
                  disabled={true}
                  style={{ width: "100%" }}
                  formatter={(value) => commaSeparateNumber(value)}
                  precision={0}
                  size="small"
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name="creation_date"
                label="Creation Date"
                rules={[
                  { required: true, message: "Please Select Creation Date!" },
                ]}
              >
                <DatePicker
                  className="w-50"
                  disabled={view}
                  onChange={(value) => {
                    setCreaetionEntryDate(value);
                  }}
                  value={
                    creaetionEntryDate
                      ? moment(creaetionEntryDate, "YYYY-MM-DD")
                      : null
                  }
                  style={{ width: "100%" }}
                  size="small"
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name="copied_previouse_style"
                label="Copy from Previous Style"
                hidden={bomId || view}
              >
                <Select
                  dropdownStyle={{ minWidth: 250 }}
                  showSearch
                  onFocus={(e) => {}}
                  placeholder="Select a Previous Style"
                  optionFilterProp="children"
                  onChange={(value) => {
                    callPreviousHistoryBom(value);
                  }}
                  style={{ width: "100%" }}
                  size="small"
                  disabled={bomId || view}
                >
                  {previousStylesList?.length &&
                    previousStylesList.map((item) => (
                      <Option value={item.id} key={item.id}>
                        {item?.style_no}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Card>
        <ItemDetails
          colorList={colorList}
          creaetionEntryDate={creaetionEntryDate}
          setCreaetionEntryDate={setCreaetionEntryDate}
          view={view}
          itemDetailsList={itemDetailsList}
          setItemDetailsList={setItemDetailsList}
          subStyleList={subStyleList}
          form={form}
          getContractIds={getContractIds}
          getContractIdDetails={getContractIdDetails}
          setSalesContractId={setSalesContractId}
          prevStyleSetter={prevStyleSetter}
          style={style}
        />
      </Form>
    </div>
  );
});

export default AddOrUpdateForm;
